import React, { useState, useEffect } from "react";
import {
  Box, Heading, Flex, Spacer, Button, Avatar, AvatarBadge, Text, Table, Thead, Tbody, Tr, Th, Td,
  IconButton, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton,
  useDisclosure, bgGradient,TableContainer,Skeleton,Image
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import axios from 'axios';
import { useTranslation } from 'react-i18next';


function Dashboard() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const [user, setUser] = useState(null); // État pour stocker les informations de l'utilisateur
  const [transactions, setTransactions] = useState([]);

  // Effet pour récupérer les informations de l'utilisateur lors du chargement initial
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`https://backend-zs1h.onrender.com/utilisateur/${localStorage.getItem('utilisateurId')}`);
        const userData = response.data.utilisateur;
        setUser(userData);
        
        // Récupérer le numéro de compte à partir des données utilisateur
        const accountNumber = userData.account_number;
        
        // Utiliser le numéro de compte pour récupérer l'historique des transactions
        const transactionResponse = await axios.get(`https://backend-zs1h.onrender.com/historique/${accountNumber}`);
        setTransactions(transactionResponse.data);
        
        setIsLoading(false); // Mettre isLoading à false une fois les données chargées
      } catch (error) {
        console.error('Erreur lors de la récupération des informations de l\'utilisateur ou de l\'historique des transactions:', error);
        setIsLoading(false); // Mettre isLoading à false en cas d'erreur
      }
    };
  
    fetchUser();
  }, []);
  

  // Effet pour récupérer l'historique des transactions
  useEffect(() => {
    const accountNumber = localStorage.getItem('account_number');
    console.log('Account Number:', accountNumber); // Afficher le account number dans la console
  
    const fetchTransactions = async () => {
      try {
        const response = await axios.get(`https://backend-zs1h.onrender.com/historique/${accountNumber}`);
        setTransactions(response.data);
        setIsLoading(false); // Mettre isLoading à false une fois les données chargées
      } catch (error) {
        console.error('Erreur lors de la récupération de l\'historique des transactions :', error);
        setIsLoading(false); // Mettre isLoading à false en cas d'erreur
      }
    };
  
    fetchTransactions();
  }, []);


  // Gestion de la déconnexion
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('utilisateurId');
    window.location.href = '/login'; // Redirection vers la page de connexion
  };

  // Vérification du token
  if (!localStorage.getItem('token')) {
    return window.location.href ='/login';
  }

  return (
    <Box>
      {/* Navbar */}
      

    

      {user && (
        <Box bg="white" p="6" boxShadow="md" borderRadius="md" mb="6">

        <Flex align="center">
         <Avatar name={`${user.nom} ${user.prenom}`} src="https://bit.ly/broken-link">
          <AvatarBadge boxSize="1.25em" bg="green.500" />
        </Avatar>
          <Box ml="3">
            <Heading size="md">{t('dashboardt.Bienvenue')}, {user.nom} {user.prenom}</Heading>
            <Box fontSize="sm">{t('dashboardt.Utilisateur-depuis')} {user.date_inscription}</Box>
            <Box fontSize="sm">{t('dashboardt.Numero-de-compte')} {user.account_number}</Box>
          </Box>
          <Spacer />
        </Flex>
        </Box>

      )}
      <Flex mt="6" wrap="wrap" justify="space-between">
      <Box p="58" boxShadow="md" borderRadius="10px" bgGradient="linear(to-r, green.200, pink.500)" bg="#FAF5FF" width={{ base: "100%", md: "18%" }} mb="4">
          <Heading size="md" mb="4">{t('dashboardt.Account-Balance')}</Heading>
          {user ? (
            <>
              <Text fontSize="30px">{user.solde} €</Text>
              <Button mt="6" colorScheme="blue">
                <Link to="/transfert">{t('dashboardt.Transfert')}</Link>
              </Button>
            </>
          ) : (
            <Text fontSize="md">{t('dashboardt.Loading...')}</Text>
          )}
        </Box>
        {user && (
        <>
          <Skeleton p="58" boxShadow="md" borderRadius="20px" bg="#FAF5FF" width={{ base: "100%", md: "38%" }} mb="4" isLoaded={!isLoading}>
            <Heading size="md" mb="4">IBAN: {user.iban}</Heading>
            <Heading size="md" mb="4">BIC: {user.bic}</Heading>
          </Skeleton>

          <Box p="6" boxShadow="md" borderRadius="20px" bgGradient="linear(to-r, blue.200, purple.500)" color="white" width={{ base: "100%", md: "28%" }} mb="4" position="relative">
        <Heading size="md" mb="4">Virtual Card</Heading>
        <Flex direction="column" alignItems="center" mb="4">
        <Text fontSize="5xl" fontWeight="bold" textAlign="center" mb="4" ml="-4">1234 5678 9012 3456</Text>
          <Flex justifyContent="space-between" width="100%" mt="10">
            <Text fontSize="lg">Expiration: 12/27</Text>
            <Text fontSize="lg">CVV: 123</Text>
          </Flex>
        </Flex>
        <Image src="./assets/mastercard.png" alt="Mastercard Logo" width="50px" position="absolute" bottom="4" left="4" />
      </Box>
        </>
      )}
        <Box p="6" boxShadow="md" borderRadius="md" bg="white" width="100%">
          <Heading size="md" mb="4">{t('dashboardt.Historique-des-Transactions')}</Heading>
          <TableContainer>
            <Table variant="striped" colorScheme='teal'>
              <Thead>
                <Tr>
                  <Th>{t('dashboardt.Name')}</Th>
                  <Th>{t('dashboardt.Account-Number')}</Th>
                  <Th>{t('dashboardt.Amount')}</Th>
                  <Th>{t('dashboardt.Date')}</Th>
                  <Th>{t('dashboardt.Status')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {/* Mapping des transactions pour les afficher dans la table */}
                {transactions.map((transaction) => (
                  <Tr key={transaction.id}>
                    {/* Vérifier si l'utilisateur est l'expéditeur */}
                    {transaction.from_user_id === user.account_number ? (
                      <>
                        <Td>{transaction.beneficiaire}</Td>
                        <Td>{transaction.to_user_id}</Td>
                      </>
                    ) : (
                      <>
                        <Td>{transaction.expediteur}</Td>
                        <Td>{transaction.from_user_id}</Td>
                      </>
                    )}
                    {/* Afficher le montant avec un signe "-" pour les transactions sortantes */}
                    <Td>{transaction.from_user_id === user.account_number ? "-" : "+"} {Math.abs(transaction.amount)} €</Td>
                    <Td>{transaction.created_at}</Td>
                    <Td>{transaction.statut}</Td>
                  </Tr>
                ))}
              </Tbody>


          </Table>
        </TableContainer>
        </Box>
      </Flex>
    </Box>
  );
}

export default Dashboard;
