import React, { useState } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const RegisterAdmin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleRegister = async () => {
    try {
      const response = await axios.post('https://backend-zs1h.onrender.com/admin/register', { username, password });
      if (response.status === 201) {
        toast.success('Inscription réussie. Vous pouvez maintenant vous connecter.');
        setTimeout(() => {
            window.location.href = '/login-Admin'; // Redirection vers la page 'react-admin' après 1 seconde de délai
          }, 1000);
      } else {
        toast.error('Erreur lors de l\'inscription. Veuillez réessayer.');
      }
    } catch (error) {
      console.error('Erreur lors de l\'inscription:', error);
      toast.error('Erreur lors de l\'inscription. Veuillez réessayer.');
    }
  };

  return (
    <div>
      <ToastContainer />
      <Card style={{ width: '20rem', margin: 'auto', marginTop: '50px', padding: '20px' }}>
        <Card.Body>
          <Card.Title>Inscription Administrateur</Card.Title>
          <Form>
            <Form.Group controlId="formBasicUsername">
              <Form.Label>Nom d'utilisateur</Form.Label>
              <Form.Control type="text" placeholder="Entrer le nom d'utilisateur" value={username} onChange={(e) => setUsername(e.target.value)} />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Mot de passe</Form.Label>
              <Form.Control type="password" placeholder="Mot de passe" value={password} onChange={(e) => setPassword(e.target.value)} />
            </Form.Group>

            <Button variant="primary" type="button" onClick={handleRegister}>
              S'inscrire
            </Button>
          </Form>
        </Card.Body>
      </Card><br/>
    </div>
  );
};

export default RegisterAdmin;
