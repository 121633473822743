// Home.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './css/style.css'; // Chemin vers le fichier CSS dans le dossier assets
import './css/animate-4.1.1.min.css'; // Chemin vers le fichier CSS dans le dossier assets
import './css/bootstrap-drawer.min.css'; // Chemin vers le fichier CSS dans le dossier assets
import './css/bootstrap.css'; // Chemin vers le fichier CSS dans le dossier assets
import './css/slick.min.css'; // Chemin vers le fichier CSS dans le dossier assets

function Home() {
    const { t } = useTranslation();
  return (
    <div>
    <div id="content">
      <div className="slider-block style-three"> 
        <div className="slider-main">
          <div className="slider-item">
            <div className="container"> 
              <div className="row flex-between item-start gap-32">
                <div className="left-block flex-column row-gap-40 col-7">
                  <div className="heading2 text-white animate__animated animate__fadeInUp animate__delay-0-2s">{t('header.textacceuil')}</div>
                  <div className="button-block animate__animated animate__fadeInUp animate__delay-0-5s"><a className="button-share display-inline-flex hover-bg-white bg-gradient text-white text-button pl-28 pr-28 pt-16 pb-16 bora-8 flex-item-center gap-8" href="/signup" rel="noreferrer"><i className="ph ph-arrow-right text-white"></i><span>{t('header.Get Free Consultant')}</span></a>
                  </div>
                </div>
                <div className="right-block flex-column row-gap-32 col-5 animate__animated animate__fadeInRight animate__delay-0-2s">
                  <div className="body2 text-placehover">{t('header.descacceuil')}</div>
                  <div className="count flex-between gap-24">
                    <div className="left">
                      <div className="heading3 text-white">1.77k</div>
                      <div className="body1 text-white mt-4">{t('header.Business Problem Solving')}</div>
                    </div>
                    <div className="right">
                      <div className="heading3 text-white">246k</div>
                      <div className="body1 text-white mt-4">{t('header.Passive income earners')}</div>
                    </div>
                  </div>
                </div>
                <div className="slider-img col-11"><img className="w-100 animate__animated animate__fadeInUp animate__delay-1s" src="assets/images/slider/graphic-three.png" alt=""/></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="list-benefit-three mt-60">
        <div className="container">
          <div className="row row-gap-60">
            <div className="col-12 col-xl-3 col-lg-6 col-md-6 col-sm-6">
                    <div className="service-item hover-box-shadow pl-32 pr-32 box-shadow-none"><a className="service-item-main flex-column gap-16" href="#!" rel="noreferrer">
                        <div className="heading flex-between"><i className="icon-hand-touch icon-gradient fs-42"></i>
                          <div className="number heading3 text-placehover"> </div>
                        </div>
                        <div className="desc">
                          <div className="heading7 hover-text-blue">{t('header.Convenience')}</div>
                          <div className="body3 text-secondary mt-4">{t('header.Conveniena')}</div>
                        </div></a>
                    </div>
            </div>
            <div className="col-12 col-xl-3 col-lg-6 col-md-6 col-sm-6">
                    <div className="service-item hover-box-shadow pl-32 pr-32 box-shadow-none"><a className="service-item-main flex-column gap-16" href="#!" rel="noreferrer">
                        <div className="heading flex-between"><i className="icon-user-lock icon-gradient fs-42"></i>
                          <div className="number heading3 text-placehover"> </div>
                        </div>
                        <div className="desc">
                          <div className="heading7 hover-text-blue">{t('header.account-access')}</div>
                          <div className="body3 text-secondary mt-4">{t('header.Rest-assured')}</div>
                        </div></a>
                    </div>
            </div>
            <div className="col-12 col-xl-3 col-lg-6 col-md-6 col-sm-6">
                    <div className="service-item hover-box-shadow pl-32 pr-32 box-shadow-none"><a className="service-item-main flex-column gap-16" href="#!" rel="noreferrer">
                        <div className="heading flex-between"><i className="icon-coin-bag icon-gradient fs-42"></i>
                          <div className="number heading3 text-placehover"> </div>
                        </div>
                        <div className="desc">
                          <div className="heading7 hover-text-blue"> {t('header.SafeTransactions')}</div>
                          <div className="body3 text-secondary mt-4">{t('header.Safe-Transactions')}</div>
                        </div></a>
                    </div>
            </div>
            <div className="col-12 col-xl-3 col-lg-6 col-md-6 col-sm-6">
                    <div className="service-item hover-box-shadow pl-32 pr-32 box-shadow-none"><a className="service-item-main flex-column gap-16" href="#!" rel="noreferrer">
                        <div className="heading flex-between"><i className="icon-chart-blue icon-gradient fs-42"></i>
                          <div className="number heading3 text-placehover"> </div>
                        </div>
                        <div className="desc">
                          <div className="heading7 hover-text-blue">{t('header.Easily-Track-Finances')}</div>
                          <div className="body3 text-secondary mt-4">{t('header.Enjoy-round')}</div>
                        </div></a>
                    </div>
            </div>
          </div>
        </div>
      </div>
      <div className="style-three">
                <div className=" layout-item mt-100">
                  <div className="container"> 
                    <div className="row row-gap-32">
                      <div className="col-12 col-lg-6 pr-40 flex-column justify-content-center">
                        <div className="heading3">{t('header.Registering')}</div>
                        <div className="body2 text-secondary mt-20">{t('header.Online-banking')}</div>
                        <div className="button-block mt-24"><a className="button-share bg-gradient hover-button-black text-white text-button display-inline-block pt-12 pb-12 pl-28 pr-28 bora-8 flex-item-center gap-8" href="/loan" rel="noreferrer"><i className="ph-bold ph-arrow-right text-white fs-20"></i><span>{t('header.Discovery')}</span></a>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 pl-55">
                        <div className="bg-video w-100 overflow-hidden bora-20"><img className="w-100 display-block" src="assets/images/blog/item2.png" alt=""/><i className="ph-fill ph-play fs-28 bg-white bora-50"></i></div>
                      </div>
                    </div>
                  </div>
                </div>
      </div>
      <div className="service-block style-about-two mt-100 pt-60 pb-60 bg-surface style-three">
        <div className="container"> 
          <div className="heading-block row flex-columns-center">
            <div className="col-12 heading3 text-center">{t('header.Our-Services')}</div>
            <div className="col-10 text-center body2 text-secondary mt-12">{t('header.Online-banking-allows')}</div>
          </div>
          <div className="list-service mt-40 pt-12">
            <div className="row row-gap-40">
              <div className="col-12 col-xl-3 col-lg-6 col-md-6 col-sm-6">
                      <div className="service-item hover-box-shadow pl-32 pr-32 pt-40 pb-40 bora-8 bg-white"><a className="service-item-main flex-column gap-16" rel="noreferrer" href="/">
                          <div className="heading flex-item-center gap-16"><i className="icon-hand-tick icon-gradient fs-60"></i>
                            <div className="heading6 hover-text-blue">{t('header.Internet-Banking')}</div>
                          </div>
                          <div className="body3 text-secondary">{t('header.With-our-Internet')}</div></a>
                      </div>
              </div>
              <div className="col-12 col-xl-3 col-lg-6 col-md-6 col-sm-6">
                      <div className="service-item hover-box-shadow pl-32 pr-32 pt-40 pb-40 bora-8 bg-white"><a className="service-item-main flex-column gap-16" rel="noreferrer" href="/">
                          <div className="heading flex-item-center gap-16"><i className="icon-coin-chair icon-gradient fs-60"></i>
                            <div className="heading6 hover-text-blue">{t('header.Mobile-Banking')}</div>
                          </div>
                          <div className="body3 text-secondary">{t('header.Our-Mobile-Banking-app')}</div></a>
                      </div>
              </div>
              <div className="col-12 col-xl-3 col-lg-6 col-md-6 col-sm-6">
                      <div className="service-item hover-box-shadow pl-32 pr-32 pt-40 pb-40 bora-8 bg-white"><a className="service-item-main flex-column gap-16" href="/">
                          <div className="heading flex-item-center gap-16"><i className="icon-coin-hand icon-gradient fs-60"></i>
                            <div className="heading6 hover-text-blue">{t('header.Money-Transfers')}</div>
                          </div>
                          <div className="body3 text-secondary">{t('header.Our-money-transfer-service')}</div></a>
                      </div>
              </div>
              <div className="col-12 col-xl-3 col-lg-6 col-md-6 col-sm-6">
                      <div className="service-item hover-box-shadow pl-32 pr-32 pt-40 pb-40 bora-8 bg-white"><a className="service-item-main flex-column gap-16" href="/">
                          <div className="heading flex-item-center gap-16"><i className="icon-hand-protect icon-gradient fs-60"></i>
                            <div className="heading6 hover-text-blue">{t('header.Account-Management')}</div>
                          </div>
                          <div className="body3 text-secondary">{t('header.Managing-your-accounts')}</div></a>
                      </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="case-studies-block style-two style-three mt-100">
        <div className="container"> 
          <div className="heading flex-between pb-40">
            <div className="heading3">{t('header.Case-Study')}</div>
            <div className="list-nav flex-center gap-8">
              <div className="nav-item text-button-small text-secondary pt-8 pb-8 pl-20 pr-20 pointer" data-name="investing">{t('header.Investing')}</div>
              <div className="nav-item text-button-small text-secondary pt-8 pb-8 pl-20 pr-20 pointer active" data-name="fintech">{t('header.Fintech')}</div>
              <div className="nav-item text-button-small text-secondary pt-8 pb-8 pl-20 pr-20 pointer" data-name="crypto">{t('header.Crypto')}</div>
              <div className="nav-item text-button-small text-secondary pt-8 pb-8 pl-20 pr-20 pointer" data-name="blockchain">{t('header.Blockchain')}</div>
              <div className="nav-item text-button-small text-secondary pt-8 pb-8 pl-20 pr-20 pointer" data-name="planning">{t('header.Planning')}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-xl-4 col-sm-6 item-filter" data-name="fintech">
              <div className="item-main"> 
                <div className="bg-img overflow-hidden"><img className="w-100 h-100 display-block" src="assets/images/blog/item4.png" alt=""/><a className="flex-columns-center pt-32 pb-32 pl-20 pr-20 bg-white bora-50 text-center" href="case-studies-detail.html">
                    <div className="text-button-small text-gradient">{t('header.Discovery')}</div><i className="ph-bold ph-arrow-up-right text-gradient"></i></a></div>
                <div className="infor bg-white bora-8 pt-24">
                  <div className="heading6">{t('header.Retirement-Planning')}</div>
                  <div className="body2 text-secondary mt-8">{t('header.Made-Financial')}</div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-4 col-sm-6 item-filter" data-name="fintech">
              <div className="item-main"> 
                <div className="bg-img overflow-hidden"><img className="w-100 h-100 display-block" src="assets/images/blog/item5.png" alt=""/><a className="flex-columns-center pt-32 pb-32 pl-20 pr-20 bg-white bora-50 text-center" href="case-studies-detail.html">
                    <div className="text-button-small text-gradient">{t('header.Discovery')}</div><i className="ph-bold ph-arrow-up-right text-gradient"></i></a></div>
                <div className="infor bg-white bora-8 pt-24">
                  <div className="heading6">{t('header.Tax-Optimization')}</div>
                  <div className="body2 text-secondary mt-8">{t('header.Retirement-Planning')}</div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-4 col-sm-6 item-filter" data-name="fintech">
              <div className="item-main"> 
                <div className="bg-img overflow-hidden"><img className="w-100 h-100 display-block" src="assets/images/blog/item6.png" alt=""/><a className="flex-columns-center pt-32 pb-32 pl-20 pr-20 bg-white bora-50 text-center" href="case-studies-detail.html">
                    <div className="text-button-small text-gradient">{t('header.Discovery')}</div><i className="ph-bold ph-arrow-up-right text-gradient"></i></a></div>
                <div className="infor bg-white bora-8 pt-24">
                  <div className="heading6">{t('header.Business-Succession')}</div>
                  <div className="body2 text-secondary mt-8">{t('header.Account-management-tools')}</div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-4 col-sm-6 item-filter" data-name="fintech">
              <div className="item-main"> 
                <div className="bg-img overflow-hidden"><img className="w-100 h-100 display-block" src="assets/images/blog/item3.png" alt=""/><a className="flex-columns-center pt-32 pb-32 pl-20 pr-20 bg-white bora-50 text-center" href="case-studies-detail.html">
                    <div className="text-button-small text-gradient">{t('header.Discovery')}</div><i className="ph-bold ph-arrow-up-right text-gradient"></i></a></div>
                <div className="infor bg-white bora-8 pt-24">
                  <div className="heading6">{t('header.Education-Funding-Strategies')}</div>
                  <div className="body2 text-secondary mt-8">{t('header.Retirement-Planning')}</div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-4 col-sm-6 item-filter" data-name="fintech">
              <div className="item-main"> 
                <div className="bg-img overflow-hidden"><img className="w-100 h-100 display-block" src="assets/images/blog/item2.png" alt=""/><a className="flex-columns-center pt-32 pb-32 pl-20 pr-20 bg-white bora-50 text-center" href="case-studies-detail.html">
                    <div className="text-button-small text-gradient">{t('header.Discovery')}</div><i className="ph-bold ph-arrow-up-right text-gradient"></i></a></div>
                <div className="infor bg-white bora-8 pt-24">
                  <div className="heading6">{t('header.Portfolio-Management')}</div>
                  <div className="body2 text-secondary mt-8">{t('header.Account-management-tools')}</div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <div className="features-three bg-surface mt-100 pt-60 pb-60">
        <div className="container"> 
          <div className="row flex-between">
            <div className="col-12 col-xl-6 pr-80">
              <div className="heading"> 
                <div className="heading3">{t('header.Features')} </div>
                <div className="body2 text-secondary mt-20">{t('header.Online-physical-bank')}</div>
              </div>
              <div className="list-nav-item mt-40">
                <div className="nav-item heading7 pt-24 pb-24 pl-20" data-name="bill-payment">{t('header.Bill-payment-and-transfer-options')}</div>
                <div className="nav-item heading7 pt-24 pb-24 pl-20 active" data-name="account-balance">{t('header.Account-balance-and-transaction-history')}</div>
                <div className="nav-item heading7 pt-24 pb-24 pl-20" data-name="mobile-banking">{t('header.Mobile-banking-and-alert')}</div>
                <div className="nav-item heading7 pt-24 pb-24 pl-20" data-name="account-management">{t('header.Account-management-tools')}</div>
              </div>
            </div>
            <div className="col-12 col-xl-5 content-filter" data-name="account-balance">
              <div className="bg-img"> <img src="assets/images/component/graphic-features3.png" alt=""/></div>
              <div className="infor bg-white pt-24 pb-24 bora-12"> 
                <div className="infor-user pl-32 pr-32 pb-24">
                  <div className="heading flex-item-center gap-16"> <img className="w-60 h-60" src="assets/images/member/avatar5.png" alt=""/>
                    <div className="desc">
                      <div className="text-button-small fw-600 text-gradient">Designe App</div>
                      <div className="name flex-item-center gap-8 mt-4">
                        <div className="heading7">Maverick Nguyen</div><i className="ph-fill ph-lightning text-white bg-gradient fs-12 p-4 bora-50"></i>
                      </div>
                    </div>
                  </div>
                  <div className="mt-32 flex-between"> 
                    <div className="heading7 text-secondary">{t('header.Account-balance')}</div>
                    <div className="text-button">$110,000</div>
                  </div>
                </div>
                <div className="line-x"></div>
                <div className="pt-24 history pl-32 pr-32">
                  <div className="desc">
                    <div className="text-button-small fw-600 text-gradient">{t('header.Transaction-History')}</div>
                    <div className="name flex-item-center gap-8 mt-4">
                      <div className="heading7">Maverick Nguyen</div><i className="ph-fill ph-lightning text-white bg-gradient fs-12 p-4 bora-50"></i>
                    </div>
                    <div className="mt-16 flex-between"> 
                      <div className="text-button">$400,000</div>
                      <div className="text-button">- $110,000</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-5 content-filter hide" data-name="bill-payment">
              <div className="bg-img"> <img src="assets/images/component/graphic-features3.png" alt=""/></div>
              <div className="infor overflow-hidden bora-20"><img className="w-100 bora-20 hover-scale display-block" src="assets/images/blog/item9.png" alt=""/></div>
            </div>
            <div className="col-12 col-xl-5 content-filter hide" data-name="mobile-banking">
              <div className="bg-img"> <img src="assets/images/component/graphic-features3.png" alt=""/></div>
              <div className="infor overflow-hidden bora-20"><img className="w-100 bora-20 hover-scale display-block" src="assets/images/blog/item10.png" alt=""/></div>
            </div>
            <div className="col-12 col-xl-5 content-filter hide" data-name="account-management">
              <div className="bg-img"> <img src="assets/images/component/graphic-features3.png" alt=""/></div>
              <div className="infor overflow-hidden bora-20"><img className="w-100 bora-20 hover-scale display-block" src="assets/images/blog/item11.png" alt=""/></div>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonials-three mt-100">
        <div className="container"> 
          <div className="heading3 text-center">{t('header.What-People-Are-Saying')}</div><br/>
          <div className="list-comment row">
            <div className="col-12 col-lg-4 col-sm-6 comment-item">
              <div className="item p-32 bg-white bora-12 box-shadow hover-box-shadow">
                <div className="body3 text-secondary">"{t('header.temoignage')}"</div>
                <div className="infor mt-16 flex-item-center gap-16">
                  <div className="avatar"><img className="w-60 h-60" src="assets/images/member/avatar1.png" alt=""/></div>
                  <div className="desc">
                    <div className="text-button">Maverick</div>
                    <div className="caption2 text-secondary mt-4">Chariman, Avitex Inc</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 col-sm-6 comment-item">
              <div className="item p-32 bg-white bora-12 box-shadow hover-box-shadow">
                <div className="body3 text-secondary">"Your personalized approach and care have improved my financial planning. I highly value your services and attention to detail in crafting financial plans."</div>
                <div className="infor mt-16 flex-item-center gap-16">
                  <div className="avatar"><img className="w-60 h-60" src="assets/images/member/avatar3.png" alt=""/></div>
                  <div className="desc">
                    <div className="text-button">Alexander</div>
                    <div className="caption2 text-secondary mt-4">Chariman, Avitex Inc</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 col-sm-6 comment-item">
              <div className="item p-32 bg-white bora-12 box-shadow hover-box-shadow">
                <div className="body3 text-secondary">"I'm extremely satisfied with your services! Your meticulous financial planning helped me manage my assets efficiently and achieve my financial goals."</div>
                <div className="infor mt-16 flex-item-center gap-16">
                  <div className="avatar"><img className="w-60 h-60" src="assets/images/member/avatar2.png" alt=""/></div>
                  <div className="desc">
                    <div className="text-button">De Rossi</div>
                    <div className="caption2 text-secondary mt-4">Chariman, Avitex Inc</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 col-sm-6 comment-item">
              <div className="item p-32 bg-white bora-12 box-shadow hover-box-shadow">
                <div className="body3 text-secondary">"I highly value and appreciate your services and the attention to detail you provide in crafting financial plans. Thank you for helping shape my financial future!"</div>
                <div className="infor mt-16 flex-item-center gap-16">
                  <div className="avatar"><img className="w-60 h-60" src="assets/images/member/avatar4.png" alt=""/></div>
                  <div className="desc">
                    <div className="text-button">Benjamin</div>
                    <div className="caption2 text-secondary mt-4">Chariman, Avitex Inc</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-cta-block benefit-three mt-100 bg-surface">
        <div className="container h-100">
          <div className="row flex-between h-100 row-gap-20">
            <div className="col-12 col-lg-6">
              <div className="heading3">{t('header.banking-online-text')}</div>
              <div className="heading7 text-secondary mt-16">{t('header.Schedule-consultation-now')}</div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="form-block bora-16 bg-white p-28 flex-columns-between gap-20">
                <div className="heading6">{t('header.Need-Help')}</div>
                <div className="row row-gap-20">
                  <div className="col-12 col-sm-6"> 
                    <input className="w-100 bg-surface caption1 pl-16 pr-16 pt-12 pb-12 bora-8" type="text" placeholder="Name"/>
                  </div>
                  <div className="col-12 col-sm-6"> 
                    <input className="w-100 bg-surface caption1 pl-16 pr-16 pt-12 pb-12 bora-8" type="text" placeholder="Email"/>
                  </div>
                  <div className="col-12"> 
                    <select className="w-100 bg-surface caption1 pl-12 pt-12 pb-12 bora-8" name="categories">
                      <option value="Financial Planning">{t('header.Financial-Planning')}</option>
                      <option value="Business Planning">{t('header.Business-Planning')}</option>
                      <option value="Development Planning">{t('header.Development-Planning')}</option>
                    </select><i className="ph ph-caret-down"></i>
                  </div>
                  <div className="col-12"> 
                    <textarea className="w-100 bg-surface caption1 pl-16 pr-16 pt-12 pb-12 bora-8" name="messsage" cols="10" rows="4" placeholder="Your Message"></textarea>
                  </div>
                </div>
                <div className="button-block"> 
                        <button className="button-share hover-bg-gradient bg-on-surface text-white text-button pl-36 pr-36 pt-12 pb-12 bora-48">{t('header.SendMessage')}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      <div className="js-video-modal">
        <div className="js-video-modal-container">
          <div className="video-block">
            <iframe src="https://www.youtube.com/embed/RaQKTgGyyyo" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
          </div>
        </div>
      </div>
      <div className="style-three"><a className="scroll-to-top-btn" href="#header"><i className="ph-bold ph-caret-up"></i></a></div>
      <div className="pb-100"></div>
    </div>
    <div id="footer">
      <div className="cta-three bg-gradient">
        <div className="container"> 
          <div className="row flex-columns-center">
            <div className="col-lg-7 col-md-8 col-11 text-center heading3 text-white pt-80">{t('header.Join')}</div>
            <div className="button-block mt-32">    <a className="button-share hover-bg-white bg-on-surface text-white text-button pl-24 pr-24 pt-12 pb-12 bora-8 flex-item-center gap-8" href="/signup"><i className="ph-bold ph-arrow-right text-white fs-20"></i><span>{t('header.Get started')}</span></a>
            </div>
            <div className="bg-img col-12 col-xl-7 col-lg-8 col-sm-11"><img className="w-100" src="assets/images/cta/cta-three.png" alt=""/></div>
          </div>
        </div>
      </div>
      
    </div>
    </div>
  );
}

export default Home;
