import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Alert, Container, Card, Row, Col } from 'react-bootstrap';
import axios from 'axios';

function UpdatePassword() {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [variant, setVariant] = useState(''); // 'success' or 'danger'

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setMessage(t('updatePassword.passwordMismatch'));
            setVariant('danger');
            return;
        }

        try {
            const response = await axios.put('https://backend-zs1h.onrender.com/update-password', {
                email: email,
                mot_de_passe: password
            });

            setMessage(t('updatePassword.updateSuccess'));
            setVariant('success');
        } catch (error) {
            if (error.response) {
                setMessage(error.response.data.error);
            } else {
                setMessage(t('updatePassword.updateError'));
            }
            setVariant('danger');
        }
    };

    return (
        <Container>
            <h1 className="mt-5 text-center">{t('updatePassword.submit')}</h1>
            <Row className="justify-content-md-center mt-3">
                <Col xs={12} md={8} lg={6}>
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder={t('updatePassword.emailPlaceholder')}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="formPassword">
                                    <Form.Label>{t('updatePassword.passwordPlaceholder')}</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder={t('updatePassword.passwordPlaceholder')}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId="formConfirmPassword">
                                    <Form.Label>{t('updatePassword.confirmPasswordPlaceholder')}</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder={t('updatePassword.confirmPasswordPlaceholder')}
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        required
                                    />
                                </Form.Group>

                                <div className="d-flex justify-content-between align-items-center">
                                    <Button variant="primary" type="submit">
                                        {t('updatePassword.submit')}
                                    </Button>
                                </div>
                            </Form>
                            {message && <Alert variant={variant} className="mt-3">{message}</Alert>}
                        </Card.Body>
                    </Card>
                </Col>
            </Row><br/>
        </Container>
    );
}

export default UpdatePassword;
