import React, { useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import './css/style.css'; // Chemin vers le fichier CSS dans le dossier assets
import './css/animate-4.1.1.min.css'; // Chemin vers le fichier CSS dans le dossier assets
import './css/bootstrap-drawer.min.css'; // Chemin vers le fichier CSS dans le dossier assets
import './css/bootstrap.css'; // Chemin vers le fichier CSS dans le dossier assets
import './css/slick.min.css';


function HomeLayout({ children }) {
  const [selectedOption, setSelectedOption] = useState("Login");
  const handleChange = (e) => {
    setSelectedOption(e.target.value);
    if (e.target.value === "Login") {
      window.location.href= "/login";
    } else if (e.target.value === "login") {
      window.location.href= '/login';
    }
  };
    function initMobileMenu() {
        var mobileMenuBtn = document.querySelector('.menu-hamburger');
        var menuMobile = document.querySelector('#menu-mobile-block');
        var menuMobileMain = document.querySelector('#menu-mobile-block .menu-mobile-main');
        var closeMobileBtn = document.querySelector('#menu-mobile-block .close-block');
        
        if (mobileMenuBtn) {
          mobileMenuBtn.addEventListener('click', function () {
            menuMobile.classList.toggle('open');
          });
        }
        
        var itemLinks = document.querySelectorAll('#menu-mobile-block .nav-item-mobile');
        itemLinks.forEach(function (item) {
          item.addEventListener('click', function () {
            item.querySelector('.sub-nav-mobile').classList.toggle('open');
          });
        });
        
        if (closeMobileBtn) {
          closeMobileBtn.addEventListener('click', function () {
            menuMobile.classList.remove('open');
          });
        }
        
        if (menuMobile) {
          menuMobile.addEventListener('click', function () {
            menuMobile.classList.remove('open');
          });
        }
        
        if (menuMobileMain) {
          menuMobileMain.addEventListener('click', function (event) {
            event.stopPropagation();
          });
        }
      }
      
      // Appeler la fonction initMobileMenu lorsque la page est chargée
      document.addEventListener('DOMContentLoaded', function() {
        initMobileMenu();
      });
      
      // Vous pouvez également appeler initMobileMenu lors du redimensionnement de la fenêtre pour gérer les changements de résolution
      window.addEventListener('resize', function() {
        initMobileMenu();
      });
      
  const { t } = useTranslation();
  return (
    <>
          <header>

          <div id="header">
      <div className="top-nav style-four bg-white">
        <div className="container flex-between h-60"><a className="left-block flex-item-center" href="#"><img className="menu-logo" src="assets/images/Logo.png" alt="logo"/></a>
          <div className="right-block flex-item-center gap-40">
            <div className="location flex-item-center"><i className="ph-light ph-map-pin fs-20"></i><span className="ml-8 caption2">93-99 Mabgate, Leeds LS9 7DR, Royaume-Uni</span></div>
            
            <select
      className="border-none outline-none pr-8 caption2"
      name="language"
      value={selectedOption}
      onChange={handleChange}
    >
      <option value="Login">Chose login</option>
      <option value="Login">Login</option>
    </select>
          </div>
        </div>
      </div>
      <div className="style-subpage style-home-four">
        <div className="header-menu style-four bg-on-surface">
          <div className="container flex-between h-88">
            <div className="menu-humburger display-none pointer"><i className="ph-bold ph-list"></i></div>
            <div className="menu-center-block h-100">
              <ul className="menu-nav flex-item-center h-100">
                <li className="nav-item h-100 flex-center home"><Link className="nav-link text-white text-sub-title" to="/">{t('header.home')}</Link>
                
                </li>
                <li className="nav-item h-100 flex-center contact"><Link to="/loan" className="nav-link text-white text-sub-title" >{t('header.loan')}</Link>
                </li>
                <li className="nav-item h-100 flex-center about"><Link to="/About" className="nav-link text-white text-sub-title">{t('header.about')}</Link>
                </li>
                <li className="nav-item h-100 flex-center services"><Link to="/Service" className="nav-link text-white text-sub-title">{t('header.service')}</Link>
                </li>
                
                <li className="nav-item h-100 flex-center contact"><Link to="/Contact" className="nav-link text-white text-sub-title" >{t('header.contact')}</Link>
                </li>
              </ul>
            </div>
            <div className="menu-right-block flex-item-center gap-20">
              <div className="button-block"><Link to="/Login" className="button-share hover-bg-white bg-success text-button pl-24 pr-24 pt-12 pb-12 bora-48">{t('header.Espace-clients')}</Link>
              </div>
              <div className="phone-block flex-item-center gap-12">
                <div className="icon-call"><i className="icon-phone-call fs-36 icon-white"></i></div>
                <div className="text">
                  <div className="text-button-small text-placehover">Call us 24/7</div>
                  <div className="number text-button text-white">+ 33 7 48 65 34 89</div>
                </div>
              </div>
            </div>
          </div>
          <div id="menu-mobile-block"> 
            <div className="menu-mobile-main"> 
              <div className="container"> 
                <ul className="menu-nav-mobile h-100 pt-4 pb-4">
                  <li className="nav-item-mobile h-100 flex-column gap-8 pt-8 pb-8 pl-12 pr-12 pointer home"><a className="fs-14 nav-link-mobile" href="#!">Home <i className="ph-fill ph-caret-down fs-12"></i></a>
                    <ul className="sub-nav-mobile">
                      <li className="sub-nav-item pl-12 pr-12 pt-8 pb-8 active"><a className="sub-nav-link fs-14" href="index-2.html">Payment Solution</a></li>
                      <li className="sub-nav-item pl-12 pr-12 pt-8 pb-8"> <a className="sub-nav-link fs-14" href="home2.html">Financial Planning</a></li>
                      <li className="sub-nav-item pl-12 pr-12 pt-8 pb-8"> <a className="sub-nav-link fs-14" href="home3.html">Online Banking</a></li>
                      <li className="sub-nav-item pl-12 pr-12 pt-8 pb-8"> <a className="sub-nav-link fs-14" href="home4.html">Personal Finance</a></li>
                      <li className="sub-nav-item pl-12 pr-12 pt-8 pb-8"> <a className="sub-nav-link fs-14" href="home5.html">Cryptocurrency Financial</a></li>
                      <li className="sub-nav-item pl-12 pr-12 pt-8 pb-8"> <a className="sub-nav-link fs-14" href="home6.html">Blockchain</a></li>
                    </ul>
                  </li>
                  <li className="nav-item-mobile h-100 flex-column gap-8 pt-4 pb-8 pl-12 pr-12 pointer about"><a className="fs-14 nav-link-mobile" href="#!">About <i className="ph-fill ph-caret-down fs-12"></i></a>
                    <ul className="sub-nav-mobile">
                      <li className="sub-nav-item pl-12 pr-12 pt-8 pb-8"><a className="sub-nav-link fs-14" href="about-one.html">About Style 1</a></li>
                      <li className="sub-nav-item pl-12 pr-12 pt-8 pb-8"> <a className="sub-nav-link fs-14" href="about-two.html">About Style 2</a></li>
                    </ul>
                  </li>
                  <li className="nav-item-mobile h-100 flex-column gap-8 pt-4 pb-8 pl-12 pr-12 pointer services"><a className="fs-14 nav-link-mobile" href="#!">Services <i className="ph-fill ph-caret-down fs-12"></i></a>
                    <ul className="sub-nav-mobile">
                      <li className="sub-nav-item pl-12 pr-12 pt-8 pb-8"><a className="sub-nav-link fs-14" href="service-one.html">Service Style 1</a></li>
                      <li className="sub-nav-item pl-12 pr-12 pt-8 pb-8"> <a className="sub-nav-link fs-14" href="service-two.html">Service Style 2</a></li>
                      <li className="sub-nav-item pl-12 pr-12 pt-8 pb-8"> <a className="sub-nav-link fs-14" href="service-detail.html">Services Detail</a></li>
                    </ul>
                  </li>
                  <li className="nav-item-mobile h-100 flex-column gap-8 pt-4 pb-8 pl-12 pr-12 pointer case"><a className="fs-14 nav-link-mobile" href="#!">Case Studies <i className="ph-fill ph-caret-down fs-12"></i></a>
                    <ul className="sub-nav-mobile">
                      <li className="sub-nav-item pl-12 pr-12 pt-8 pb-8"> <a className="sub-nav-link fs-14" href="case-studies-one.html">Case Studies 1</a></li>
                      <li className="sub-nav-item pl-12 pr-12 pt-8 pb-8"> <a className="sub-nav-link fs-14" href="case-studies-two.html">Case Studies 2</a></li>
                      <li className="sub-nav-item pl-12 pr-12 pt-8 pb-8"> <a className="sub-nav-link fs-14" href="case-studies-detail.html">Case Studies Detail</a></li>
                    </ul>
                  </li>
                  <li className="nav-item-mobile h-100 flex-column gap-8 pt-4 pb-8 pl-12 pr-12 pointer pages"><a className="fs-14 nav-link-mobile" href="#!">Pages <i className="ph-fill ph-caret-down fs-12"></i></a>
                    <ul className="sub-nav-mobile">
                      <li className="sub-nav-item pl-12 pr-12 pt-8 pb-8"><a className="sub-nav-link fs-14" href="faqs.html">FAQs</a></li>
                      <li className="sub-nav-item pl-12 pr-12 pt-8 pb-8"> <a className="sub-nav-link fs-14" href="pricing.html">Pricing</a></li>
                      <li className="sub-nav-item pl-12 pr-12 pt-8 pb-8"> <a className="sub-nav-link fs-14" href="partners.html">Partners</a></li>
                    </ul>
                  </li>
                  <li className="nav-item-mobile h-100 flex-column gap-8 pt-4 pb-8 pl-12 pr-12 pointer blog"><a className="fs-14 nav-link-mobile" href="#!">Blog <i className="ph-fill ph-caret-down fs-12"></i></a>
                    <ul className="sub-nav-mobile">
                      <li className="sub-nav-item pl-12 pr-12 pt-8 pb-8"><a className="sub-nav-link fs-14" href="blog-list-one.html">Blog List 1</a></li>
                      <li className="sub-nav-item pl-12 pr-12 pt-8 pb-8"> <a className="sub-nav-link fs-14" href="blog-list-two.html">Blog List 2</a></li>
                      <li className="sub-nav-item pl-12 pr-12 pt-8 pb-8"><a className="sub-nav-link fs-14" href="blog-grid.html">Blog Grid</a></li>
                      <li className="sub-nav-item pl-12 pr-12 pt-8 pb-8"> <a className="sub-nav-link fs-14" href="blog-masonry.html">Blog Masonry</a></li>
                      <li className="sub-nav-item pl-12 pr-12 pt-8 pb-8"><a className="sub-nav-link fs-14" href="blog-detail-one.html">Blog Detail 1</a></li>
                      <li className="sub-nav-item pl-12 pr-12 pt-8 pb-8"> <a className="sub-nav-link fs-14" href="blog-detail-two.html">Blog Detail 2</a></li>
                    </ul>
                  </li>
                  <li className="nav-item-mobile h-100 flex-column gap-8 pt-4 pb-8 pl-12 pr-12 pointer contact"><a className="fs-14 nav-link-mobile" href="#!">Contact <i className="ph-fill ph-caret-down fs-12"></i></a>
                    <ul className="sub-nav-mobile">
                      <li className="sub-nav-item pl-12 pr-12 pt-8 pb-8"><a className="sub-nav-link fs-14" href="contact-one.html">Contact Style 1</a></li>
                      <li className="sub-nav-item pl-12 pr-12 pt-8 pb-8"> <a className="sub-nav-link fs-14" href="contact-two.html">Contact Style 2</a></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </header>

    <main>{children}</main>
    <footer>
        {/* Footer for Home Layout */}
        <div id="footer">
      <div className="style-four">
        <div className="footer-block bg-dark pt-60">
          <div className="container">
            <div className="row flex-between pb-40">
              <div className="col-3">
                <div className="footer-company-infor flex-columns-between gap-20"><img className="footer-logo" src="assets/images/Logo.png" alt="Logo"/>
                  <div className="text caption1 text-white">{t('header.Descfooter')}</div>
                  <div className="list-social flex-item-center gap-10 style-one"><a className="item bora-50 w-28 h-28 border-grey-2px flex-center" href="https://www.facebook.com/" target="_blank"><i className="icon-facebook fs-12"></i></a><a className="item bora-50 w-28 h-28 border-grey-2px flex-center" href="https://www.linkedin.com/" target="_blank"><i className="icon-in fs-12"></i></a><a className="item bora-50 w-28 h-28 border-grey-2px flex-center" href="https://www.twitter.com/" target="_blank"><i className="icon-twitter fs-10"></i></a><a className="item bora-50 w-28 h-28 border-grey-2px flex-center" href="https://www.instagram.com/" target="_blank"><i className="icon-insta fs-10"></i></a><a className="item bora-50 w-28 h-28 border-grey-2px flex-center" href="https://www.youtube.com/" target="_blank"><i className="icon-youtube fs-10"></i></a></div>
                </div>
              </div>
              <div className="col-6">
                <div className="footer-navigate flex-center gap-80">
                  <div className="footer-nav-item">
                    <div className="item-heading text-button-small text-white">Quick Links</div>
                    <ul className="list-nav mt-4">
                      <li className="mt-12"><Link className="caption1 text-line hover-underline" to="/about">{t('header.about')}</Link></li>
                      <li className="mt-12"><Link className="caption1 text-line hover-underline" to="/service">{t('header.service')}</Link></li>
                      <li className="mt-12"><Link className="caption1 text-line hover-underline" to="/contact">{t('header.contact')}</Link></li>
                    </ul>
                  </div>
                  
                  
                </div>
              </div>
              <div className="col-3">
                <div className="company-contact"> 
                  <div className="heading text-button-small text-white">Newsletter</div>
                  <div className="mt-12 flex-item-center"> 
                    <svg width="36" height="36" viewbox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M26.43 16.1254C25.785 16.1254 25.275 15.6004 25.275 14.9704C25.275 14.4154 24.72 13.2604 23.79 12.2554C22.875 11.2804 21.87 10.7104 21.03 10.7104C20.385 10.7104 19.875 10.1854 19.875 9.55539C19.875 8.92539 20.4 8.40039 21.03 8.40039C22.53 8.40039 24.105 9.21039 25.485 10.6654C26.775 12.0304 27.6 13.7254 27.6 14.9554C27.6 15.6004 27.075 16.1254 26.43 16.1254Z" fill="#C1D8FF"></path>
                      <path d="M31.8446 16.125C31.1996 16.125 30.6896 15.6 30.6896 14.97C30.6896 9.645 26.3546 5.325 21.0446 5.325C20.3996 5.325 19.8896 4.8 19.8896 4.17C19.8896 3.54 20.3996 3 21.0296 3C27.6296 3 32.9996 8.37 32.9996 14.97C32.9996 15.6 32.4746 16.125 31.8446 16.125Z" fill="#C1D8FF"></path>
                      <path d="M17.685 21.315L12.78 26.22C12.24 25.74 11.715 25.245 11.205 24.735C9.66 23.175 8.265 21.54 7.02 19.83C5.79 18.12 4.8 16.41 4.08 14.715C3.36 13.005 3 11.37 3 9.81C3 8.79 3.18 7.815 3.54 6.915C3.9 6 4.47 5.16 5.265 4.41C6.225 3.465 7.275 3 8.385 3C8.805 3 9.225 3.09 9.6 3.27C9.99 3.45 10.335 3.72 10.605 4.11L14.085 9.015C14.355 9.39 14.55 9.735 14.685 10.065C14.82 10.38 14.895 10.695 14.895 10.98C14.895 11.34 14.79 11.7 14.58 12.045C14.385 12.39 14.1 12.75 13.74 13.11L12.6 14.295C12.435 14.46 12.36 14.655 12.36 14.895C12.36 15.015 12.375 15.12 12.405 15.24C12.45 15.36 12.495 15.45 12.525 15.54C12.795 16.035 13.26 16.68 13.92 17.46C14.595 18.24 15.315 19.035 16.095 19.83C16.635 20.355 17.16 20.865 17.685 21.315Z" fill="#C1D8FF"></path>
                      <path d="M32.9554 27.4955C32.9554 27.9155 32.8804 28.3505 32.7304 28.7705C32.6854 28.8905 32.6404 29.0105 32.5804 29.1305C32.3254 29.6705 31.9954 30.1805 31.5604 30.6605C30.8254 31.4705 30.0154 32.0555 29.1004 32.4305C29.0854 32.4305 29.0704 32.4455 29.0554 32.4455C28.1704 32.8055 27.2104 33.0005 26.1754 33.0005C24.6454 33.0005 23.0104 32.6405 21.2854 31.9055C19.5604 31.1705 17.8354 30.1805 16.1254 28.9355C15.5404 28.5005 14.9554 28.0655 14.4004 27.6005L19.3054 22.6955C19.7254 23.0105 20.1004 23.2505 20.4154 23.4155C20.4904 23.4455 20.5804 23.4905 20.6854 23.5355C20.8054 23.5805 20.9254 23.5955 21.0604 23.5955C21.3154 23.5955 21.5104 23.5055 21.6754 23.3405L22.8154 22.2155C23.1904 21.8405 23.5504 21.5555 23.8954 21.3755C24.2404 21.1655 24.5854 21.0605 24.9604 21.0605C25.2454 21.0605 25.5454 21.1205 25.8754 21.2555C26.2054 21.3905 26.5504 21.5855 26.9254 21.8405L31.8904 25.3655C32.2804 25.6355 32.5504 25.9505 32.7154 26.3255C32.8654 26.7005 32.9554 27.0755 32.9554 27.4955Z" fill="#2868D8"></path>
                    </svg>
                    <div className="text ml-16">
                      <div className="caption2 text-line">Need help? 24/7 </div>
                      <div className="fw-700 text-white mt-4">+33 7 48 65 34 89</div>
                    </div>
                  </div>
                  <div className="locate mt-12 flex-item-center"> <i className="ph-light ph-map-pin text-line"></i>
                    <div className="caption1 text-line ml-8">93-99 Mabgate, Leeds LS9 7DR, Royaume-Uni</div>
                  </div>
                  <div className="send-block mt-20 flex-item-center">
                    <input className="caption1 text-secondary" type="text" placeholder="Your email address"/>
                    <button className="flex-center"><i className="ph ph-paper-plane-tilt text-white"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-line"></div>
            <div className="footer-bottom flex-between pt-12 pb-12">
              <div className="left-block flex-item-center">
                <div className="copy-right text-line caption1">©2024 Novoxia. All Rights Reserved.</div>
              </div>
              <div className="nav-link flex-item-center gap-10"><a className="text-line caption1 hover-underline" href="#!">Terms Of Services</a><span className="text-line caption1">|</span><a className="text-line caption1 hover-underline" href="#!">Privacy Policy</a><span className="text-line caption1">|</span><a className="text-line caption1 hover-underline" href="#!">Cookie Policy</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
      </footer>
      <Outlet />

    </>
  )
};

export default HomeLayout;