import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, FormLabel, Input, Button, VStack, Text, useToast, Spinner } from '@chakra-ui/react';

function Login() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [motDePasse, setMotDePasse] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false); // Variable d'état pour indiquer si la requête est en cours
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { email, motDePasse };

    try {
      setLoading(true); // Définir loading sur true au début de la requête
      const response = await axios.post('https://backend-zs1h.onrender.com/login', data);
      console.log('Response:', response.data);
      const { token, utilisateurId } = response.data;

      localStorage.setItem('token', token);
      localStorage.setItem('utilisateurId', utilisateurId);

      setSuccess('Login successful');
      setError('');
      toast({
        title: 'Login successful',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      setTimeout(() => {
        window.location.href = '/dashboard'; // Rediriger vers l'URL spécifiée ou vers le tableau de bord par défaut
      }, 2000); 
    } catch (error) {
      if (error.response) {
        console.error('Erreur lors de la connexion :', error.response.data);
        setError(`Erreur lors de la connexion : ${error.response.data.message}`);
      } else {
        console.error('Erreur lors de la connexion :', error.message);
        setError('Erreur lors de la connexion');
      }
      setSuccess('');
      toast({
        title: 'Erreur lors de la connexion',
        description: error.response ? error.response.data.message : error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false); // Définir loading sur false à la fin de la requête
    }
  };
  return (

    <div>
        <div id="content">
            <div className="slider-sub ">
              <div className="bg-img"> <img src="assets/images/banner/contact.png" alt="banner"/></div>
              <div className="container"> 
                <div className="heading-nav gap-4 mt-32"><a className="hover-underline caption1 text-white" href="index-2.html">Home</a><i className="ph ph-caret-double-right text-white"></i>
                  <div className="caption1 text-white">Login</div>
                </div>
                <div className="text-nav"> 
                  <div className="heading3 text-white">{t('login.title')}</div>
                  <div className="sub-heading mt-8 text-white fw-400">{t('login.desc')}</div>
                </div>
              </div>
            </div>
      <div className="form-contact style-one mt-100"> 
        <div className="container"> 
          <div className="row flex-center row-gap-32">
           
            <div className="col-12 col-xl-6 pl-55">
              <div className="form-block flex-columns-between gap-20">
                <div className="heading">
                  <div className="heading5">{t('login.test')}</div>
                </div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
              {success && <p style={{ color: 'green' }}>{success}</p>}
              <form onSubmit={handleSubmit}>
            <div className="row row-gap-20">
                <div className="col-12">
                    <label className="col-12">{t('login.email')}</label>
                    <input
                        className="w-100 bg-surface text-secondary caption1 pl-16 pr-16 pt-12 pb-12 bora-8"
                        type="email"
                        placeholder="Enter your Email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="col-12">
                    <label className="col-12">{t('login.password')}</label>
                    <input
                        className="w-100 bg-surface text-secondary caption1 pl-16 pr-16 pt-12 pb-12 bora-8"
                        type="password"
                        placeholder="Enter your Password"
                        id="motDePasse"
                        value={motDePasse}
                        onChange={(e) => setMotDePasse(e.target.value)}
                    />
                </div>
            </div>
            <br />
            <div className="d-flex justify-content-between align-items-center">
                <div className="button-block">
                    {loading ? (
                        <Button
                            type="submit"
                            colorScheme="blue"
                            variant="solid"
                            size="lg"
                            isLoading
                            loadingText="Loading..."
                        >
                            {t('login.submit')}
                        </Button>
                    ) : (
                        <Button
                            type="submit"
                            colorScheme="blue"
                            variant="solid"
                            size="lg"
                        >
                            {t('login.submit')}
                        </Button>
                    )}
                </div>
                <Link to="/update-password" className="ml-3">Mot de Passe Oublié?</Link>
            </div>
        </form>
              </div>
             
            </div>
          </div>
        </div>
      </div><a className="scroll-to-top-btn" href="#header"><i className="ph-bold ph-caret-up"></i></a>
      <div className="pb-100"></div>
    </div>

    </div>

    );
}

export default Login;
