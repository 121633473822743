import React, { useState, useEffect } from "react";
import {Box,Heading,Flex,Spacer,Button,Avatar,Card,AvatarBadge,Text,Table,Thead,Tbody,Tr,Th,Td,IconButton,Drawer,DrawerBody,DrawerHeader,DrawerOverlay,DrawerContent,DrawerCloseButton,useDisclosure,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import axios from 'axios';

function Setting() {
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const [user, setUser] = useState(null); // État pour stocker les informations de l'utilisateur

  // Effet pour récupérer les informations de l'utilisateur lors du chargement initial
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`http://localhost:3000/utilisateur/${localStorage.getItem('utilisateurId')}`);
        setUser(response.data.utilisateur);
      } catch (error) {
        console.error('Erreur lors de la récupération des informations de l\'utilisateur:', error);
      }
    };

    fetchUser();
  }, []);
  if (!localStorage.getItem('token')) {
    return window.location.href ='/login';
  }


  return (
    <Box p="6">
      {/* Navbar */}
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        padding="1.5rem"
        bg="blue.500"
        color="white"
        mb="8"
      >
        <Flex align="center" mr={5}>
          <IconButton
            icon={<HamburgerIcon />}
            aria-label="Open Menu"
            ref={btnRef}
            onClick={onOpen}
            display={{ base: "block", md: "none" }}
            bg="transparent"
            _hover={{ bg: "transparent" }}
          />
          <Heading as="h1" size="lg" letterSpacing={"tighter"}>
            Settings
          </Heading>
        </Flex>

        <Spacer />

        <Box display={{ base: "none", md: "flex" }} alignItems="center">
          <Button colorScheme="blue" mr="4">
            Save
          </Button>
        </Box>
      </Flex>

      {/* Drawer Menu */}
      <Drawer isOpen={isOpen} placement="left" onClose={onClose} finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody>
            <Button w="100%" mb="4">
              Profile
            </Button>
            <Button w="100%" mb="4">
              Account
            </Button>
            <Button w="100%" mb="4">
              Security
            </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {user && (
      <Flex align="center" mb="4">
        <Avatar name={`${user.nom} ${user.prenom}`} src="https://bit.ly/broken-link">
          <AvatarBadge boxSize="1.25em" bg="green.500" />
        </Avatar>
        <Box ml="3">
          <Heading size="md">Welcome, {user.nom} {user.prenom}</Heading>
          <Box fontSize="sm">User since {user.date_inscription}</Box>
        </Box>
        <Spacer />
      </Flex>
  )}
      <Flex mt="6" wrap="wrap" justify="space-between">
        <Box p="6" boxShadow="md" borderRadius="md" bg="white" width={{ base: "100%", md: "48%" }} mb="4">
          <Heading size="md" mb="4">
            Profile
          </Heading>
          {user ? (
            <>
            <Card>
            <Text fontSize="md">Profile settings here...</Text>
            </Card>

             </>
          ) : (
          <Text fontSize="md">Profile settings here...</Text>
        )}
        </Box>
        <Box p="6" boxShadow="md" borderRadius="md" bg="white" width={{ base: "100%", md: "48%" }} mb="4">
          <Heading size="md" mb="4">
            Account
          </Heading>
          <Text fontSize="md">Account settings here...</Text>
        </Box>
        <Box p="6" boxShadow="md" borderRadius="md" bg="white" width="100%">
          <Heading size="md" mb="4">
            Security
          </Heading>
          <Text fontSize="md">Security settings here...</Text>
        </Box>
      </Flex>
    </Box>
  );
}

export default Setting;
