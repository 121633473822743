import React from "react";
import { useTranslation } from "react-i18next";

function Service(){
    const {t} = useTranslation();
    return(

        <div>
 <div id="content">
            <div className="slider-sub ">
              <div className="bg-img"> <img src="assets/images/banner/about1.png" alt="banner"/></div>
              <div className="container"> 
                <div className="heading-nav gap-4 mt-32"><a className="hover-underline caption1 text-white" href="index-2.html">Home</a><i className="ph ph-caret-double-right text-white"></i>
                  <div className="caption1 text-white">Services</div>
                </div>
                <div className="text-nav"> 
                  <div className="heading3 text-white">Our Services</div>
                  <div className="sub-heading mt-8 text-white fw-400">We perform a full analysis of the client’s website and collect information about all the competitors to formulate a proper strategy.</div>
                </div>
              </div>
            </div>
      <div className="bottom-slider-three mt-100">
        <div className="container"> 
          <div className="row row-gap-32 column-reverse-xl">
            <div className="col-12 col-xl-6 flex-columns-between row-gap-20 pr-40">
              <div className="heading3">Effective Risk Management Solutions for Financial Stability</div>
              <div className="body2 text-secondary">We offer reliable Risk Management services to safeguard your assets, ensure financial stability, and navigate rapidly changing business environments. We understand that managing risks is crucial for the sustainability and success of your enterprise</div>
              <div className="button-block"><a className="button-share hover-button-black text-white bg-blue text-button display-inline-block pt-12 pb-12 pl-36 pr-36 bora-48" href="service-detail.html">Discovery</a>
              </div>
            </div>
            <div className="col-12 col-xl-6">
              <div className="bg-img w-100 overflow-hidden bora-20"><img className="w-100 h-100 hover-scale display-block" src="assets/images/component/bottom-slider-three.png" alt=""/></div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-block mt-80">
        <div className="container"> 
          <div className="list-service row mt-32 row-gap-32">
            <div className="col-12 col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div className="service-item hover-box-shadow bora-8 p-32 bg-white border-line-1px"><a className="service-item-main flex-column gap-16" href="service-cryptocurrency-trading.html">
                        <div className="heading flex-between"><i className="icon-coin-chair text-blue fs-60"></i>
                          <div className="number heading3 text-placehover">01</div>
                        </div>
                        <div className="desc">
                          <div className="heading7 hover-text-blue">Cryptocurrency Trading</div>
                          <div className="body3 text-secondary mt-4">Experience the excitement and potential of the cryptocurrency market with our expert trading services.</div>
                        </div>
                        <div className="read-block flex-item-center gap-4 hover-text-blue"><span className="fs-14 fw-700 text-blue">Read More</span><i className="ph-bold ph-caret-double-right fs-12 text-blue"></i></div></a>
                    </div>
            </div>
            <div className="col-12 col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div className="service-item hover-box-shadow bora-8 p-32 bg-white border-line-1px"><a className="service-item-main flex-column gap-16" href="service-portfolio-management.html">
                        <div className="heading flex-between"><i className="icon-hand-tick text-blue fs-60"></i>
                          <div className="number heading3 text-placehover">02</div>
                        </div>
                        <div className="desc">
                          <div className="heading7 hover-text-blue">Portfolio Management</div>
                          <div className="body3 text-secondary mt-4">We analyze market trends, manage risks, and optimize your portfolio to maximize returns and achieve your financial goals.</div>
                        </div>
                        <div className="read-block flex-item-center gap-4 hover-text-blue"><span className="fs-14 fw-700 text-blue">Read More</span><i className="ph-bold ph-caret-double-right fs-12 text-blue"></i></div></a>
                    </div>
            </div>
            <div className="col-12 col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div className="service-item hover-box-shadow bora-8 p-32 bg-white border-line-1px"><a className="service-item-main flex-column gap-16" href="service-investment-advice.html">
                        <div className="heading flex-between"><i className="icon-hand-house text-blue fs-60"></i>
                          <div className="number heading3 text-placehover">03</div>
                        </div>
                        <div className="desc">
                          <div className="heading7 hover-text-blue">Investment Advice</div>
                          <div className="body3 text-secondary mt-4">Our team of experienced advisors will guide you in making informed investment decisions, whether you're a beginner or an experienced trader.</div>
                        </div>
                        <div className="read-block flex-item-center gap-4 hover-text-blue"><span className="fs-14 fw-700 text-blue">Read More</span><i className="ph-bold ph-caret-double-right fs-12 text-blue"></i></div></a>
                    </div>
            </div>
            <div className="col-12 col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div className="service-item hover-box-shadow bora-8 p-32 bg-white border-line-1px"><a className="service-item-main flex-column gap-16" href="service-detail.html">
                        <div className="heading flex-between"><i className="icon-gear-warning text-blue fs-60"></i>
                          <div className="number heading3 text-placehover">04</div>
                        </div>
                        <div className="desc">
                          <div className="heading7 hover-text-blue">Risk Management</div>
                          <div className="body3 text-secondary mt-4">We employ industry-leading tools and techniques to protect your investments and minimize potential losses.</div>
                        </div>
                        <div className="read-block flex-item-center gap-4 hover-text-blue"><span className="fs-14 fw-700 text-blue">Read More</span><i className="ph-bold ph-caret-double-right fs-12 text-blue"></i></div></a>
                    </div>
            </div>
            <div className="col-12 col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div className="service-item hover-box-shadow bora-8 p-32 bg-white border-line-1px"><a className="service-item-main flex-column gap-16" href="service-research-analysis.html">
                        <div className="heading flex-between"><i className="icon-text-search text-blue fs-60"></i>
                          <div className="number heading3 text-placehover">05</div>
                        </div>
                        <div className="desc">
                          <div className="heading7 hover-text-blue">Research and Analysis</div>
                          <div className="body3 text-secondary mt-4">We provide timely reports, market updates, and data-driven insights to help you make informed trading decisions.</div>
                        </div>
                        <div className="read-block flex-item-center gap-4 hover-text-blue"><span className="fs-14 fw-700 text-blue">Read More</span><i className="ph-bold ph-caret-double-right fs-12 text-blue"></i></div></a>
                    </div>
            </div>
            <div className="col-12 col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div className="service-item hover-box-shadow bora-8 p-32 bg-white border-line-1px"><a className="service-item-main flex-column gap-16" href="service-education-resources.html">
                        <div className="heading flex-between"><i className="icon-education text-blue fs-52"></i>
                          <div className="number heading3 text-placehover">06</div>
                        </div>
                        <div className="desc">
                          <div className="heading7 hover-text-blue">Education and Resources</div>
                          <div className="body3 text-secondary mt-4">Expand your knowledge and skills in cryptocurrency trading through our educational resources.</div>
                        </div>
                        <div className="read-block flex-item-center gap-4 hover-text-blue"><span className="fs-14 fw-700 text-blue">Read More</span><i className="ph-bold ph-caret-double-right fs-12 text-blue"></i></div></a>
                    </div>
            </div>
          </div>
        </div>
      </div>
      <div className="partner-five-col style-one mt-60 pb-60 bg-white">
        <div className="list pt-32 pb-32">
          <div className="bg-img flex-center"><img className="w-100" src="assets/images/partner/lh.png" alt=""/></div>
          <div className="bg-img flex-center"><img className="w-100" src="assets/images/partner/shang.png" alt=""/></div>
          <div className="bg-img flex-center"><img className="w-100" src="assets/images/partner/pana.png" alt=""/></div>
          <div className="bg-img flex-center"><img className="w-100" src="assets/images/partner/masa.png" alt=""/></div>
          <div className="bg-img flex-center"><img className="w-100" src="assets/images/partner/van.png" alt=""/></div>
          <div className="bg-img flex-center"><img className="w-100" src="assets/images/partner/pana.png" alt=""/></div>
          <div className="bg-img flex-center"><img className="w-100" src="assets/images/partner/shang.png" alt=""/></div>
          <div className="bg-img flex-center"><img className="w-100" src="assets/images/partner/lh.png" alt=""/></div>
          <div className="bg-img flex-center"><img className="w-100" src="assets/images/partner/masa.png" alt=""/></div>
          <div className="bg-img flex-center"><img className="w-100" src="assets/images/partner/van.png" alt=""/></div>
          <div className="bg-img flex-center"><img className="w-100" src="assets/images/partner/lh.png" alt=""/></div>
          <div className="bg-img flex-center"><img className="w-100" src="assets/images/partner/shang.png" alt=""/></div>
        </div>
      </div><a className="scroll-to-top-btn" href="#header"><i className="ph-bold ph-caret-up"></i></a>
    </div>
        </div>
    );
}

export default Service;