import React, { useState } from "react";
import {
    Box,Flex,Text,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Button,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    useToast,
    Td,
  } from "@chakra-ui/react";
  import "./loan.css";
  import { useTranslation } from 'react-i18next';
  import axios from 'axios'; // Assurez-vous d'importer axios ou votre bibliothèque HTTP préférée

function Loan() {
    const { t } = useTranslation();
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const toast = useToast();
    const [loanAmount, setLoanAmount] = useState(0);
    const [interestRate, setInterestRate] = useState(0);
    const [loanTerm, setLoanTerm] = useState(0);
    const [monthlyPayment, setMonthlyPayment] = useState(0);
    const [totalInterest, setTotalInterest] = useState(0);
    const [totalAmountToPay, setTotalAmountToPay] = useState(0);
    const [amortizationTable, setAmortizationTable] = useState([]);
    const [formData, setFormData] = useState({
      fullName: '',
      email: '',
      phone: '',
      loanAmount: '',
    });
  
    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };
  
    const handleSubmitLoanRequest = async (e) => {
      e.preventDefault();
  
      try {
        // Remplacez par votre URL et configuration d'axios pour l'envoi des données au backend
        const response = await axios.post('https://backend-zs1h.onrender.com/api/loan-request', formData);
        console.log('Réponse du serveur:', response.data);
        setSuccess('Demande envoyer');
        setError('');
        toast({
          title: 'Demande envoyer',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        // Gérer la réponse du serveur ici (par exemple, afficher un message de succès)
      } catch (error) {
        console.error('Erreur lors de l\'envoi de la requête:', error);
        setError('Erreur lors de l\'envoi de la requête');
        setSuccess('');
        toast({
          title: 'Erreur lors de l\'envoi de la requête',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };
  
    const calculateMonthlyPayment = () => {
      const monthlyInterestRate = interestRate / 1200; // Convertir le taux d'intérêt annuel en taux mensuel
      const termInMonths = loanTerm * 12; // Convertir la durée du prêt en mois
  
      const monthlyPayment =
        (loanAmount * monthlyInterestRate) /
        (1 - Math.pow(1 + monthlyInterestRate, -termInMonths));
  
      setMonthlyPayment(monthlyPayment.toFixed(2)); // Fixer le paiement mensuel à deux décimales
    };
  
    const generateAmortizationTable = () => {
      const monthlyInterestRate = interestRate / 1200; // Convertir le taux d'intérêt annuel en taux mensuel
      const termInMonths = loanTerm * 12; // Convertir la durée du prêt en mois
  
      let remainingBalance = loanAmount;
      let amortizationData = [];
      let totalInterest = 0;
  
      for (let i = 1; i <= termInMonths; i++) {
        const interestPayment = remainingBalance * monthlyInterestRate;
        const principalPayment = monthlyPayment - interestPayment;
  
        remainingBalance -= principalPayment;
  
        amortizationData.push({
          month: i,
          payment: monthlyPayment.toFixed(2),
          principal: principalPayment.toFixed(2),
          interest: interestPayment.toFixed(2),
          balance: remainingBalance.toFixed(2),
        });
  
        totalInterest += parseFloat(interestPayment.toFixed(2));
      }
  
      setAmortizationTable(amortizationData);
      setTotalInterest(totalInterest.toFixed(2));
      setTotalAmountToPay((parseFloat(loanAmount) + totalInterest).toFixed(2));
    };
  
    const handleCalculate = () => {
      calculateMonthlyPayment();
      generateAmortizationTable();
    };
    return(
        <div>
        <div id="content">
      <div class="slider-block style-four"> 
        <div class="prev-arrow flex-center"><i class="ph-bold ph-caret-left text-white"></i></div>
        <div class="slider-main bg-white">
          <div class="slider-item">
            <div class="bg-img"> <img class="w-100 h-100" src="assets/images/slider/slider4-1.png" alt=""/></div>
            <div class="container"> 
              <div class="text-content">
              <div
  className="heading2 animate__animated animate__fadeInUp animate__delay-0-2s"
  dangerouslySetInnerHTML={{ __html: t('loan.getMoneyToday') }}
/>
<div
      className="body2 mt-12 animate__animated animate__fadeInUp animate__delay-0-5s"
      dangerouslySetInnerHTML={{ __html: t('loan.loanText') }}
    />                <div class="button-block flex-item-center mt-40 gap-30 animate__animated animate__fadeInUp animate__delay-0-8s"><a class="button-share display-inline-block hover-button-black bg-success text-button pl-28 pr-28 pt-12 pb-12 bora-8" href="#request">Request a credit</a><a class="button-share display-inline-flex bg-transparent text-button bora-8 flex-item-center gap-8" href="#video"><i class="ph-fill ph-play text-critical fs-14"></i><span>Watch Demos</span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="next-arrow flex-center"><i class="ph-bold ph-caret-right text-white"></i></div>
      </div>
      <div class="style-four">
        <div class="partner-five-col style-one mt-100 bg-blue">
          <div class="list pt-32 pb-32">
            <div class="bg-img flex-center"><img class="w-100" src="assets/images/partner/Logo.png" alt=""/></div>
            <div class="bg-img flex-center"><img class="w-100" src="assets/images/partner/Logo-1.png" alt=""/></div>
            <div class="bg-img flex-center"><img class="w-100" src="assets/images/partner/Logo-2.png" alt=""/></div>
            <div class="bg-img flex-center"><img class="w-100" src="assets/images/partner/Logo-3.png" alt=""/></div>
            <div class="bg-img flex-center"><img class="w-100" src="assets/images/partner/Logo-4.png" alt=""/></div>
            <div class="bg-img flex-center"><img class="w-100" src="assets/images/partner/Logo-5.png" alt=""/></div>
            <div class="bg-img flex-center"><img class="w-100" src="assets/images/partner/Logo.png" alt=""/></div>
            <div class="bg-img flex-center"><img class="w-100" src="assets/images/partner/Logo-1.png" alt=""/></div>
            <div class="bg-img flex-center"><img class="w-100" src="assets/images/partner/Logo-2.png" alt=""/></div>
            <div class="bg-img flex-center"><img class="w-100" src="assets/images/partner/Logo-3.png" alt=""/></div>
            <div class="bg-img flex-center"><img class="w-100" src="assets/images/partner/Logo-4.png" alt=""/></div>
            <div class="bg-img flex-center"><img class="w-100" src="assets/images/partner/Logo-5.png" alt=""/></div>
          </div>
        </div>
      </div>
      
      <div class="service-style-four pt-100 bg-white" id="request">
        <div class="heading3 text-center">{t('loan.requestloan')}</div>
        <Text textAlign="center" mt="4" color="gray.500">
        {t('loan.textloan')}
</Text>
        <div class="service-block bg-white">
          <div class="container"> 
            <div class="list-service row mt-32 row-gap-32">
            <Box p="6">
      <Flex justify="space-between" align="flex-start" flexWrap="wrap">
        <Box flex="1 1 400px" mr={[0, 4]} mb={[4, 0]}>
          <Heading as="h2" size="lg" mb="6">
          {t('loan.calcule')}
          </Heading>
          <FormControl id="loanAmount" mb="4">
          <FormLabel>{t('loan.loanAmountLabel')}</FormLabel>
            <Input
              type="number"
              value={loanAmount}
              onChange={(e) => setLoanAmount(parseFloat(e.target.value))}
              required
            />
          </FormControl>
          <FormControl id="interestRate" mb="4">
            <FormLabel>{t('loan.interestRateLabel')}</FormLabel>
            <Input
              type="number"
              value={interestRate}
              onChange={(e) => setInterestRate(parseFloat(e.target.value))}
              required
            />
          </FormControl>
          <FormControl id="loanTerm" mb="4">
            <FormLabel>{t('loan.loanTermLabel')}</FormLabel>
            <Input
              type="number"
              value={loanTerm}
              onChange={(e) => setLoanTerm(parseFloat(e.target.value))}
              required
            />
          </FormControl>
          <Button colorScheme="blue" onClick={handleCalculate} mb="4">
          {t('loan.calculateButton')}
          </Button>
          {monthlyPayment > 0 && (
            <>
              <Heading as="h3" size="md" mb="2">
              {t('loan.monthlyPayment')}: {monthlyPayment} €
              </Heading>
              <Heading as="h4" size="sm" mb="2">
              {t('loan.totalInterest')}: {totalInterest} €
              </Heading>
              <Heading as="h4" size="sm" mb="2">
              {t('loan.totalAmountToPay')}: {totalAmountToPay} €
              </Heading>
            </>
          )}
        </Box>
        {/* Formulaire de demande de prêt */}
        <Box flex="1 1 400px">
          <Heading as="h2" size="lg" mb="6">
          {t('loan.loanRequestTitle')}
          </Heading>
          <form onSubmit={handleSubmitLoanRequest}>
          <FormControl id="fullName" mb="4">
            <FormLabel>{t('loan.fullNameLabel')}</FormLabel>
            <Input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
          </FormControl>
          <FormControl id="email" mb="4">
            <FormLabel>{t('loan.emailLabel')}</FormLabel>
            <Input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </FormControl>
          <FormControl id="phone" mb="4">
            <FormLabel>{t('loan.phoneLabel')}</FormLabel>
            <Input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </FormControl>
          <FormControl id="loanAmount" mb="4">
            <FormLabel>{t('loan.amount')}</FormLabel>
            <Input
              type="number"
              name="loanAmount"
              value={formData.loanAmount}
              onChange={handleChange}
              required
            />
          </FormControl>
          <Button colorScheme="blue" type="submit">
              Soumettre
          </Button>
        </form>
        </Box>
        {monthlyPayment > 0 && (
          <Box flex="1 1 400px">
            <Heading as="h3" size="md" mb="2">
            {t('loan.amortizationTableTitle')}
            </Heading>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>{t('loan.monthHeader')}</Th>
                  <Th>{t('loan.paymentHeader')}</Th>
                  <Th>{t('loan.principalHeader')}</Th>
                  <Th>{t('loan.interestHeader')}</Th>
                  <Th>{t('loan.remainingBalanceHeader')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {amortizationTable.map((row) => (
                  <Tr key={row.month}>
                    <Td>{row.month}</Td>
                    <Td>{row.payment}</Td>
                    <Td>{row.principal}</Td>
                    <Td>{row.interest}</Td>
                    <Td>{row.balance}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
      </Flex>
    </Box>
              
              
              
              <div class="col-12 col-xl-3 col-lg-6 col-md-6 col-sm-6 item-filter hide" data-name="personal financial">
                        <div class="service-item hover-box-shadow bora-12 p-32 bg-white border-line-1px"><a class="service-item-main flex-column gap-16" href="service-education-resources.html">
                            <div class="heading flex-between"><i class="icon-text-search icon-on-surface fs-60"></i>
                              <div class="number heading3 text-placehover"> </div>
                            </div>
                            <div class="desc">
                              <div class="heading7 hover-text-blue">Research Analysis</div>
                              <div class="body3 text-secondary mt-4">Stay ahead of market trends and make data-driven decisions with our comprehensive research and analysis.</div>
                            </div>
                            <div class="read-block flex-item-center gap-4 hover-text-blue"><span class="fs-14 fw-700 text-blue">Read More</span><i class="ph-bold ph-caret-double-right fs-12 text-blue"></i></div></a>
                        </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="video-block style-four pt-100 bg-white" id="video">
        <div class="container"> 
          <div class="video w-100"><img class="w-100 display-block" src="assets/images/component/thumbnail4.png" alt=""/><i class="ph-fill ph-play text-critical fs-28 bg-white p-28 bora-50"></i></div>
        </div>
      </div>
      <div class="count-number-block style-four bg-white">
        <div class="container">
          <div class="row row-gap-32">
            <div class="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6 flex-columns-center">
              <div class="count-block flex-item-center">
                <div class="counter heading3 text-white">2.3</div><span class="heading3 text-white">k</span>
              </div>
              <div class="body1 text-white">Business Setup Growth</div>
            </div>
            <div class="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6 flex-columns-center">
              <div class="count-block flex-item-center">
                <div class="counter heading3 text-white">1.77</div><span class="heading3 text-white">k</span>
              </div>
              <div class="body1 text-white">Business Problem Solving</div>
            </div>
            <div class="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6 flex-columns-center">
              <div class="count-block flex-item-center">
                <div class="counter heading3 text-white">298</div><span class="heading3 text-white">k</span>
              </div>
              <div class="body1 text-white">Goal achiever</div>
            </div>
            <div class="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6 flex-columns-center">
              <div class="count-block flex-item-center">
                <div class="counter heading3 text-white">246</div><span class="heading3 text-white">k</span>
              </div>
              <div class="body1 text-white">Passive income earners</div>
            </div>
          </div>
        </div>
      </div>
      <div class="js-video-modal">
        <div class="js-video-modal-container">
          <div class="video-block">
            <iframe src="https://www.youtube.com/embed/RaQKTgGyyyo" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
          </div>
        </div>
      </div>
      
      
  
      
     
      <div class="style-four"><a class="scroll-to-top-btn" href="#header"><i class="ph-bold ph-caret-up"></i></a></div>
    </div>
    </div>
    )
    
};

export default Loan;