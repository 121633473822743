import React, { useState } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const LoginAdmin = () => {
  const [username, setUsername] = useState('');
  const [motDePasse, setmotDePasse] = useState('');

  const handleLogin = async () => {
    try {
      const response = await axios.post('https://backend-zs1h.onrender.com/admin/login', { username, motDePasse });
      if (response.status === 200) {
        toast.success('Authentification réussie. Redirection en cours...');
        setTimeout(() => {
          window.location.href = '/react-admin'; // Redirection vers la page 'react-admin' après 1 seconde de délai
        }, 1000);
      } else {
        toast.error('Identifiants incorrects. Veuillez réessayer.');
      }
    } catch (error) {
      console.error('Erreur lors de la connexion:', error);
      toast.error('Erreur lors de la connexion. Veuillez réessayer.');
    }
  };

  return (
    <div>
      <ToastContainer />
      <Card style={{ width: '20rem', margin: 'auto', marginTop: '50px', padding: '20px' }}>
        <Card.Body>
          <Card.Title>Connexion Administrateur</Card.Title>
          <Form>
            <Form.Group controlId="formBasicUsername">
              <Form.Label>Nom d'utilisateur</Form.Label>
              <Form.Control type="text" placeholder="Entrer le nom d'utilisateur" value={username} onChange={(e) => setUsername(e.target.value)} />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Mot de passe</Form.Label>
              <Form.Control type="password" placeholder="Mot de passe" value={motDePasse} onChange={(e) => setmotDePasse(e.target.value)} />
            </Form.Group>

            <Button variant="primary" type="button" onClick={handleLogin}>
              Se connecter
            </Button>
          </Form>
        </Card.Body>
      </Card><br/>
    </div>
  );
};

export default LoginAdmin;
