import React from "react";
import { useTranslation } from "react-i18next";
import './css/style.css'; // Chemin vers le fichier CSS dans le dossier assets
import './css/animate-4.1.1.min.css'; // Chemin vers le fichier CSS dans le dossier assets
import './css/bootstrap-drawer.min.css'; // Chemin vers le fichier CSS dans le dossier assets
import './css/bootstrap.css'; // Chemin vers le fichier CSS dans le dossier assets
import './css/slick.min.css';


function About (){

    const {t} = useTranslation();
    return(
        <div>
<div id="content">
            <div className="slider-sub ">
              <div className="bg-img"> <img src="assets/images/banner/about1.png" alt="banner"/></div>
              <div className="container"> 
                <div className="heading-nav gap-4 mt-32"><a className="hover-underline caption1 text-white" href="index-2.html">Home</a><i className="ph ph-caret-double-right text-white"></i>
                  <div className="caption1 text-white">About Us</div>
                </div>
                <div className="text-nav"> 
                  <div className="heading3 text-white">About Us</div>
                  <div className="sub-heading mt-8 text-white fw-400">We perform a full analysis of the client’s website and collect information about all the competitors to formulate a proper strategy.</div>
                </div>
              </div>
            </div>
      <div className="financial-assessment-block pt-100 pb-100 bg-white">
        <div className="container">
          <div className="row flex-item-center">
            <div className="col-12 col-xl-6 col-lg-12">
              <div className="bg-img w-100 overflow-hidden bora-20"><img className="w-100 h-100 hover-scale display-block bora-20" src="assets/images/component/assessment.png" alt=""/></div>
            </div>
            <div className="col-12 col-xl-6 col-lg-12 flex-column">
              <div className="heading3 ml-40">company overview</div>
              <div className="nav-infor ml-40 mt-40">
                <div className="list-nav flex-item-center gap-40">
                  <div className="nav-item active" data-name="about-us">About Us</div>
                  <div className="nav-item" data-name="mission">Mission</div>
                  <div className="nav-item" data-name="vision">Vision</div>
                </div>
                <div className="description item-filter" data-name="about-us">
                  <div className="title body3 text-secondary mt-16">We're a devoted team providing premium financial solutions. Explore our mission, values, and achievements as we empower clients for a secure financial future.</div>
                  <div className="more-infor mt-24">
                    <div className="infor flex-item-center gap-12"> <i className="ph-fill ph-check-circle fs-20 text-blue"></i>
                      <div className="text-button">Identification of monthly income</div>
                    </div>
                    <div className="infor flex-item-center gap-12 mt-12"> <i className="ph-fill ph-check-circle fs-20 text-blue"></i>
                      <div className="text-button">Creation of savings and investment plan</div>
                    </div>
                    <div className="infor flex-item-center gap-12 mt-12"> <i className="ph-fill ph-check-circle fs-20 text-blue"></i>
                      <div className="text-button">Management and calculation of monthly expenses</div>
                    </div>
                  </div>
                </div>
                <div className="description item-filter hide" data-name="mission">
                  <div className="title body3 text-secondary mt-16">Our mission is to provide comprehensive and personalized financial solutions that empower our clients to achieve their financial goals and secure their future.</div>
                  <div className="more-infor mt-24">
                    <div className="infor flex-item-center gap-12"> <i className="ph-fill ph-check-circle fs-20 text-blue"></i>
                      <div className="text-button">Management and calculation of monthly expenses</div>
                    </div>
                    <div className="infor flex-item-center gap-12 mt-12"> <i className="ph-fill ph-check-circle fs-20 text-blue"></i>
                      <div className="text-button">Identification of monthly income</div>
                    </div>
                    <div className="infor flex-item-center gap-12 mt-12"> <i className="ph-fill ph-check-circle fs-20 text-blue"></i>
                      <div className="text-button">Creation of savings and investment plan</div>
                    </div>
                  </div>
                </div>
                <div className="description item-filter hide" data-name="vision">
                  <div className="title body3 text-secondary mt-16">Our unwavering vision is to be the most trusted and preferred partner in achieving financial success, diligently guiding our valued clients toward a secure and prosperous future.</div>
                  <div className="more-infor mt-24">
                    <div className="infor flex-item-center gap-12"> <i className="ph-fill ph-check-circle fs-20 text-blue"></i>
                      <div className="text-button">Creation of savings and investment plan</div>
                    </div>
                    <div className="infor flex-item-center gap-12 mt-12"> <i className="ph-fill ph-check-circle fs-20 text-blue"></i>
                      <div className="text-button">Management and calculation of monthly expenses</div>
                    </div>
                    <div className="infor flex-item-center gap-12 mt-12"> <i className="ph-fill ph-check-circle fs-20 text-blue"></i>
                      <div className="text-button">Identification of monthly income</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="button-block flex-item-center gap-20 ml-40 mt-40 pb-8"><a className="button-share text-white bg-blue hover-button-black text-button pt-14 pb-14 pl-36 pr-36 bora-48" href="contact-two.html">Get started</a><a className="button-share text-on-surface hover-button-black bg-white text-button pt-12 pb-12 pl-36 pr-36 bora-48 border-blue-2px flex-item-center gap-8" href="contact-two.html"><i className="ph ph-phone fs-20"></i><span>(00) 33 7 48 65 34 89</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="count-number-block">
        <div className="container"> 
          <div className="row row-gap-32">
            <div className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6 flex-columns-center">
              <div className="count-block flex-item-center">
                <div className="counter heading3">2.3</div><span className="heading3">k</span>
              </div>
              <div className="body1 text-secondary">Business Setup Growth</div>
            </div>
            <div className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6 flex-columns-center">
              <div className="count-block flex-item-center">
                <div className="counter heading3">1.77</div><span className="heading3">k</span>
              </div>
              <div className="body1 text-secondary">Business Problem Solving</div>
            </div>
            <div className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6 flex-columns-center">
              <div className="count-block flex-item-center">
                <div className="counter heading3">298</div><span className="heading3">k</span>
              </div>
              <div className="body1 text-secondary">Goal achiever</div>
            </div>
            <div className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6 flex-columns-center">
              <div className="count-block flex-item-center">
                <div className="counter heading3">246</div><span className="heading3">k</span>
              </div>
              <div className="body1 text-secondary">Passive income earners</div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-block mt-100 pt-100 pb-100 bg-surface">
        <div className="container"> 
          <div className="heading-block text-center">
            <div className="heading3">Our Services</div>
            <div className="body3 text-secondary mt-12">Customized financial services designed to meet your unique needs and drive your financial success</div>
          </div>
          <div className="list-service row mt-32 row-gap-24">
            <div className="col-12 col-xl-3 col-lg-6 col-md-6 col-sm-6">
                    <div className="service-item hover-box-shadow bora-8 pt-32 pb-32 pl-28 pr-28 bg-white"><a className="service-item-main flex-column gap-16" href="service-cryptocurrency-trading.html">
                        <div className="heading flex-item-center gap-16"><i className="icon-coin-hand text-blue fs-42"></i>
                          <div className="heading6 hover-text-blue">Budget planning</div>
                        </div>
                        <div className="body3 text-secondary">Experience the convenience of banking with easy access to your accounts anytime, anywhere.</div>
                        <div className="explore-block flex-item-center gap-4">
                          <div className="text-button-small text-blue">Explore Plan</div><i className="ph-bold ph-caret-double-right fs-12 text-blue"></i>
                        </div></a>
                    </div>
            </div>
            <div className="col-12 col-xl-3 col-lg-6 col-md-6 col-sm-6">
                    <div className="service-item hover-box-shadow bora-8 pt-32 pb-32 pl-28 pr-28 bg-white"><a className="service-item-main flex-column gap-16" href="service-investment-advice.html">
                        <div className="heading flex-item-center gap-16"><i className="icon-coin-pig text-blue fs-42"></i>
                          <div className="heading6 hover-text-blue">Savings advice</div>
                        </div>
                        <div className="body3 text-secondary">Confidently trust our savings advice for secure transactions and a prosperous future.</div>
                        <div className="explore-block flex-item-center gap-4">
                          <div className="text-button-small text-blue">Explore Plan</div><i className="ph-bold ph-caret-double-right fs-12 text-blue"></i>
                        </div></a>
                    </div>
            </div>
            <div className="col-12 col-xl-3 col-lg-6 col-md-6 col-sm-6">
                    <div className="service-item hover-box-shadow bora-8 pt-32 pb-32 pl-28 pr-28 bg-white"><a className="service-item-main flex-column gap-16" href="service-portfolio-management.html">
                        <div className="heading flex-item-center gap-16"><i className="icon-coin-virus text-blue fs-42"></i>
                          <div className="heading6 hover-text-blue">Finance personal</div>
                        </div>
                        <div className="body3 text-secondary">Rest assured with our robust security measures to protect your transactions and sensitive.</div>
                        <div className="explore-block flex-item-center gap-4">
                          <div className="text-button-small text-blue">Explore Plan</div><i className="ph-bold ph-caret-double-right fs-12 text-blue"></i>
                        </div></a>
                    </div>
            </div>
            <div className="col-12 col-xl-3 col-lg-6 col-md-6 col-sm-6">
                    <div className="service-item hover-box-shadow bora-8 pt-32 pb-32 pl-28 pr-28 bg-white"><a className="service-item-main flex-column gap-16" href="service-research-analysis.html">
                        <div className="heading flex-item-center gap-16"><i className="icon-hand-protect text-blue fs-42"></i>
                          <div className="heading6 hover-text-blue">Insurance advice</div>
                        </div>
                        <div className="body3 text-secondary">Stay in control of your finances with effortless tracking and monitoring of your transactions.</div>
                        <div className="explore-block flex-item-center gap-4">
                          <div className="text-button-small text-blue">Explore Plan</div><i className="ph-bold ph-caret-double-right fs-12 text-blue"></i>
                        </div></a>
                    </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-team-block pt-100 bg-white">
        <div className="container"> 
          <div className="heading-block">
            <div className="heading3">Our Professional Team</div>
          </div>
          <div className="list-member mt-40 row row-gap-32">
            <div className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className="bg-img w-100"><img className="w-100 h-100 display-block" src="assets/images/member/mem1.png" alt=""/>
                <div className="list-social bg-white"><a href="http://facebook.com/" target="_blank"> <i className="icon-facebook fs-14"></i></a><a href="http://linkedin.com/" target="_blank"> <i className="icon-in fs-14"></i></a><a href="http://twitter.com/" target="_blank"> <i className="icon-twitter fs-12"></i></a><a href="http://instagram.com/" target="_blank"> <i className="icon-insta fs-12"></i></a></div>
              </div>
              <div className="infor text-center pt-16">
                <div className="name heading6">Maria Takado</div>
                <div className="caption1 text-secondary">Senior UI-UX Designer </div>
              </div>
            </div>
            <div className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className="bg-img w-100"><img className="w-100 h-100 display-block" src="assets/images/member/mem2.png" alt=""/>
                <div className="list-social bg-white"><a href="http://facebook.com/" target="_blank"> <i className="icon-facebook fs-14"></i></a><a href="http://linkedin.com/" target="_blank"> <i className="icon-in fs-14"></i></a><a href="http://twitter.com/" target="_blank"> <i className="icon-twitter fs-12"></i></a><a href="http://instagram.com/" target="_blank"> <i className="icon-insta fs-12"></i></a></div>
              </div>
              <div className="infor text-center pt-16">
                <div className="name heading6">Kim Naika </div>
                <div className="caption1 text-secondary">CEO Technology Avitex</div>
              </div>
            </div>
            <div className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className="bg-img w-100"><img className="w-100 h-100 display-block" src="assets/images/member/mem3.png" alt=""/>
                <div className="list-social bg-white"><a href="http://facebook.com/" target="_blank"> <i className="icon-facebook fs-14"></i></a><a href="http://linkedin.com/" target="_blank"> <i className="icon-in fs-14"></i></a><a href="http://twitter.com/" target="_blank"> <i className="icon-twitter fs-12"></i></a><a href="http://instagram.com/" target="_blank"> <i className="icon-insta fs-12"></i></a></div>
              </div>
              <div className="infor text-center pt-16">
                <div className="name heading6">John Doe</div>
                <div className="caption1 text-secondary">Creative Director at Avitex </div>
              </div>
            </div>
            <div className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className="bg-img w-100"><img className="w-100 h-100 display-block" src="assets/images/member/mem4.png" alt=""/>
                <div className="list-social bg-white"><a href="http://facebook.com/" target="_blank"> <i className="icon-facebook fs-14"></i></a><a href="http://linkedin.com/" target="_blank"> <i className="icon-in fs-14"></i></a><a href="http://twitter.com/" target="_blank"> <i className="icon-twitter fs-12"></i></a><a href="http://instagram.com/" target="_blank"> <i className="icon-insta fs-12"></i></a></div>
              </div>
              <div className="infor text-center pt-16">
                <div className="name heading6">Dave Nguyen</div>
                <div className="caption1 text-secondary">CEO Technology Avitex</div>
              </div>
            </div>
            <div className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className="bg-img w-100"><img className="w-100 h-100 display-block" src="assets/images/member/mem2.png" alt=""/>
                <div className="list-social bg-white"><a href="http://facebook.com/" target="_blank"> <i className="icon-facebook fs-14"></i></a><a href="http://linkedin.com/" target="_blank"> <i className="icon-in fs-14"></i></a><a href="http://twitter.com/" target="_blank"> <i className="icon-twitter fs-12"></i></a><a href="http://instagram.com/" target="_blank"> <i className="icon-insta fs-12"></i></a></div>
              </div>
              <div className="infor text-center pt-16">
                <div className="name heading6">Christina</div>
                <div className="caption1 text-secondary">CEO Technology Avitex</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="partner-five-col style-one mt-60 pb-60 bg-white">
        <div className="list pt-32 pb-32">
          <div className="bg-img flex-center"><img className="w-100" src="assets/images/partner/lh.png" alt=""/></div>
          <div className="bg-img flex-center"><img className="w-100" src="assets/images/partner/shang.png" alt=""/></div>
          <div className="bg-img flex-center"><img className="w-100" src="assets/images/partner/pana.png" alt=""/></div>
          <div className="bg-img flex-center"><img className="w-100" src="assets/images/partner/masa.png" alt=""/></div>
          <div className="bg-img flex-center"><img className="w-100" src="assets/images/partner/van.png" alt=""/></div>
          <div className="bg-img flex-center"><img className="w-100" src="assets/images/partner/pana.png" alt=""/></div>
          <div className="bg-img flex-center"><img className="w-100" src="assets/images/partner/shang.png" alt=""/></div>
          <div className="bg-img flex-center"><img className="w-100" src="assets/images/partner/lh.png" alt=""/></div>
          <div className="bg-img flex-center"><img className="w-100" src="assets/images/partner/masa.png" alt=""/></div>
          <div className="bg-img flex-center"><img className="w-100" src="assets/images/partner/van.png" alt=""/></div>
          <div className="bg-img flex-center"><img className="w-100" src="assets/images/partner/lh.png" alt=""/></div>
          <div className="bg-img flex-center"><img className="w-100" src="assets/images/partner/shang.png" alt=""/></div>
        </div>
      </div><a className="scroll-to-top-btn" href="#header"><i className="ph-bold ph-caret-up"></i></a>
    </div>
        </div>
    );
}

export default About;