import React, { useState, useEffect } from "react";
import {
  Box, Heading, Flex, Spacer, Button, Avatar, AvatarBadge, Text, Table, Thead, Tbody, Tr, Th, Td,
  IconButton, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton,
  useDisclosure, bgGradient,TableContainer,Skeleton,Image,Container,
  FormControl,
  FormLabel,
  Input,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  VStack, Textarea
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Navbar, Nav, Row, Col, Card, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faSms, faHome, faUser, faChartBar, faCreditCard, faMoneyCheckAlt, faCog } from '@fortawesome/free-solid-svg-icons';

function SendSms(){
    const { t } = useTranslation();
    const [destinataire, setDestinataire] = useState('');
    const [msg, setMsg] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [error, setError] = useState('');
    const [user, setUser] = useState(null); // État pour stocker les informations de l'utilisateur
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      try {
        const response = await fetch('https://backend-zs1h.onrender.com/send-otp', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ destinataire, msg }),
        });
  
        const data = await response.json();
  
        if (response.ok) {
          setResponseMessage(data.message);
          setError('');
        } else {
          setError(data.error);
          setResponseMessage('');
        }
      } catch (err) {
        setError("Une erreur s'est produite lors de l'envoi du SMS.");
        setResponseMessage('');
      }
    };
    return(
        <>
 <Navbar bg="primary" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="#home">Novoxia Admin</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </Container>
      </Navbar>

      <div className="sidebar">
      <Nav className="flex-column">
        <Nav.Link href="#home" className="sidebar-link"><FontAwesomeIcon icon={faHome} /> Accueil</Nav.Link>
        <Nav.Link href="#profile" className="sidebar-link"><FontAwesomeIcon icon={faUser} /> Profil</Nav.Link>
        <NavDropdown title={<span><FontAwesomeIcon icon={faChartBar} /> Statistiques</span>} id="basic-nav-dropdown" className="sidebar-link">
          <NavDropdown.Item href="#transactions">Transactions</NavDropdown.Item>
          <NavDropdown.Item href="#customers">Clients</NavDropdown.Item>
          <NavDropdown.Item href="#loans">Prêts</NavDropdown.Item>
        </NavDropdown>
        <Nav.Link href="#cards" className="sidebar-link"><FontAwesomeIcon icon={faCreditCard} /> Cartes</Nav.Link>
        <Nav.Link href="#loans" className="sidebar-link"><FontAwesomeIcon icon={faMoneyCheckAlt} /> Prêts</Nav.Link>
      </Nav>
    </div>

<Container>
      <VStack spacing={4} align="stretch">
        <Heading as="h1" size="xl" mt={4}>Envoyer un Sms</Heading>
        <Box as="form" onSubmit={handleSubmit}>
          <FormControl id="formDestinataire" mb={4}>
            <FormLabel>Destinataire</FormLabel>
            <Input
              type="text"
              placeholder="Entrez le numéro de destinataire"
              value={destinataire}
              onChange={(e) => setDestinataire(e.target.value)}
            />
          </FormControl>

          <FormControl id="formMessage" mb={4}>
            <FormLabel>Message</FormLabel>
            <Textarea
              placeholder="Entrez votre message"
              value={msg}
              onChange={(e) => setMsg(e.target.value)}
            />
          </FormControl>

          <Button type="submit" colorScheme="blue" mt={4}>
            Envoyer
          </Button>
        </Box>

        {responseMessage && (
          <Alert status="success" mt={4}>
            <AlertIcon />
            <AlertTitle>Succès!</AlertTitle>
            <AlertDescription>{responseMessage}</AlertDescription>
          </Alert>
        )}
        {error && (
          <Alert status="error" mt={4}>
            <AlertIcon />
            <AlertTitle>Erreur!</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
      </VStack>
    </Container>
        
        </>
    )

}

export default SendSms