// Home.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './css/style.css'; // Chemin vers le fichier CSS dans le dossier assets
import './css/animate-4.1.1.min.css'; // Chemin vers le fichier CSS dans le dossier assets
import './css/bootstrap-drawer.min.css'; // Chemin vers le fichier CSS dans le dossier assets
import './css/bootstrap.css'; // Chemin vers le fichier CSS dans le dossier assets
import './css/slick.min.css'; // Chemin vers le fichier CSS dans le dossier assets

function Contact() {
    const { t } = useTranslation();
  return (
    <div>
  <div id="content">
            <div className="slider-sub ">
              <div className="bg-img"> <img src="assets/images/banner/contact.png" alt="banner"/></div>
              <div className="container"> 
                <div className="heading-nav gap-4 mt-32"><a className="hover-underline caption1 text-white" href="index-2.html">Home</a><i className="ph ph-caret-double-right text-white"></i>
                  <div className="caption1 text-white">Contact Us</div>
                </div>
                <div className="text-nav"> 
                  <div className="heading3 text-white">Contact Us</div>
                  <div className="sub-heading mt-8 text-white fw-400">Explore our Contact Us page for prompt assistance from our dedicated team.</div>
                </div>
              </div>
            </div>
      <div className="form-contact style-two mt-100"> 
        <div className="container"> 
          <div className="row flex-between row-gap-32">
            <div className="col-12 col-lg-5">
              <div className="infor flex-columns-between row-gap-32">
                <div className="heading">
                  <div className="title">Contact us</div>
                  <div className="heading3 mt-12">Get it touch</div>
                  <div className="body3 mt-8">We will get back to you within 24 hours, or call us everyday</div>
                </div>
                <div className="list-more-infor">
                  <div className="item flex-item-center gap-12"><i className="ph-fill ph-phone text-white bg-on-surface p-8 bora-50"></i>
                    <div className="line-y"> </div>
                    <div className="body2">+33 7 48 65 34 89</div>
                  </div>
                  <div className="item flex-item-center gap-12 mt-20"><i className="ph-bold ph-envelope-simple text-white bg-on-surface p-8 bora-50"></i>
                    <div className="line-y"> </div>
                    <div className="body2">support@novoxia.online</div>
                  </div>
                  <div className="item flex-item-center gap-12 mt-20"><i className="ph-bold ph-map-pin text-white bg-on-surface p-8 bora-50"></i>
                    <div className="line-y"> </div>
                    <div className="body2">93-99 Mabgate, Leeds LS9 7DR, Royaume-Uni</div>
                  </div>
                </div>
                <div className="list-social flex-item-center gap-10"><a className="item bora-50 w-48 h-48 flex-center bg-surface" href="https://www.facebook.com/" target="_blank"><i className="icon-facebook icon-on-surface"></i></a><a className="item bora-50 w-48 h-48 flex-center bg-surface" href="https://www.linkedin.com/" target="_blank"><i className="icon-in icon-on-surface"></i></a><a className="item bora-50 w-48 h-48 flex-center bg-surface" href="https://www.twitter.com/" target="_blank"><i className="icon-twitter fs-14 icon-on-surface ml-1"></i></a><a className="item bora-50 w-48 h-48 flex-center bg-surface" href="https://www.instagram.com/" target="_blank"><i className="icon-insta fs-14 icon-on-surface"></i></a><a className="item bora-50 w-48 h-48 flex-center bg-surface" href="https://www.youtube.com/" target="_blank"><i className="icon-youtube fs-12 icon-on-surface"></i></a></div>
              </div>
            </div>
            <div className="col-12 col-lg-5">
              <div className="form-block flex-columns-between gap-20">
                <div className="row row-gap-20">
                  <div className="col-12 col-sm-6">
                    <input className="w-100 bg-surface text-secondary caption1 pl-16 pr-16 pt-12 pb-12 bora-8" type="text" placeholder="Name"/>
                  </div>
                  <div className="col-12 col-sm-6">     
                    <input className="w-100 bg-surface text-secondary caption1 pl-16 pr-16 pt-12 pb-12 bora-8" type="text" placeholder="Subject"/>
                  </div>
                  <div className="col-12">
                    <input className="w-100 bg-surface text-secondary caption1 pl-16 pr-16 pt-12 pb-12 bora-8" type="text" placeholder="Email"/>
                  </div>
                  <div className="col-12"> 
                    <select className="w-100 bg-surface text-secondary caption1 pl-12 pt-12 pb-12 bora-8" name="categories">
                      <option value="Financial Planning">Financial Planning</option>
                      <option value="Business Planning">Business Planning</option>
                      <option value="Development Planning">Development Planning</option>
                    </select><i className="ph ph-caret-down"></i>
                  </div>
                  <div className="col-12"> 
                    <textarea className="w-100 bg-surface text-secondary caption1 pl-16 pr-16 pt-12 pb-12 bora-8" name="messsage" cols="10" rows="4" placeholder="Your Questions..."></textarea>
                  </div>
                </div>
                <div className="button-block">
                                  <button className="button-share bg-on-surface text-button text-white pl-36 pr-36 pt-12 pb-12 bora-48">Send Message</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="map-block mt-100">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16894.73660956101!2d-99.18411676499272!3d19.41781376470434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d201b420c8c849%3A0x84231ae36f6ec524!2sMuseo%20Nacional%20de%20Antropolog%C3%ADa!5e0!3m2!1svi!2s!4v1689137436245!5m2!1svi!2s" width="800" height="600" style={{ border: '0' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div><a className="scroll-to-top-btn" href="#header"><i className="ph-bold ph-caret-up"></i></a>
    </div>

    </div>
    );
}

export default Contact;
