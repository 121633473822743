import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import Setting from './components/AreaClient/setting';
import Transfer from './components/AreaClient/transfert';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Home from './components/home';
import About from './components/about';
import HomeLayout from './components/HomeLayout';
import Service from './components/service';
import Dashboard from './components/AreaClient/index';
import Login from './components/login';
import Contact from './components/contact';
import translationEN from './locales/en/translation.json';
import translationFR from './locales/fr/translation.json';
import translationDE from './locales/de/translation.json';
import translationES from './locales/es/translation.json';
import translationNL from './locales/nl/translation.json';
import translationIT from './locales/it/translation.json';
import translationGR from './locales/gr/translation.json';
import translationHU from './locales/hu/translation.json';
import translationBG from './locales/bg/translation.json';
import translationHR from './locales/hr/translation.json';
import translationFI from './locales/fi/translation.json';
import translationLT from './locales/lt/translation.json';
import translationPT from './locales/pt/translation.json';
import translationPL from './locales/pl/translation.json';
import translationRO from './locales/ro/translation.json';
import translationSK from './locales/sk/translation.json';
import translationTR from './locales/tr/translation.json';
import Layout from './components/AreaClient/dashboardlayout';
import Register from './components/signup';
import Validation from './components/AreaClient/validation';
import Validations from './components/AreaClient/validationns';
import Auth from './components/AreaClient/authcode';
import Loan from './components/loan';
import Admin from './Admin';
import Update from './Admin/update';
import LoginAdmin from './Admin/Login';
import RegisterAdmin from './Admin/Registers';
import UpdatePassword from './components/UpdatePassword';
import Loader from './components/AreaClient/loader';
import Loader2 from './components/AreaClient/Loader2';
import Loader3 from './components/AreaClient/Loader3';
import SendSms from './Admin/SendSms';
import LoanRequests from './Admin/loans';
import UserTable from './Admin/userTable';
// Initialise i18n
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationEN },
      fr: { translation: translationFR },
      nl: { translation: translationNL },
      de: { translation: translationDE },
      hu: { translation: translationHU },
      bg: { translation: translationBG },
      hr: { translation: translationHR },
      fi: { translation: translationFI },
      lt: { translation: translationLT },
      pt: { translation: translationPT },
      pl: { translation: translationPL },
      ro: { translation: translationRO },
      sk: { translation: translationSK },
      tr: { translation: translationTR },
      gr: { translation: translationGR },
      it: { translation: translationIT },
      es: { translation: translationES }
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });


function App() {
  useEffect(() => {
    async function detectLanguage() {
      try {
        const response = await fetch('https://ipinfo.io/json');
        const data = await response.json();
        const countryCode = data.country; // Obtenez le code de pays à partir des données de géolocalisation
        let language = 'en'; // Définir une langue par défaut
  
        // Déterminez la langue en fonction du code de pays
        switch (countryCode) {
          case 'FR':
            language = 'fr';
            break;
          case 'NL':
            language = 'nl';
            break;
          case 'DE':
            language = 'de';
            break;
          case 'GR':
            language = 'gr';
            break;
          case 'IT':
            language = 'it';
            break;
          case 'ES':
            language = 'es';
            break;
          case 'HU':
            language = 'hu';
            break;
          case 'BG':
            language = 'bg';
            break;
          case 'HR':
            language = 'hr';
            break;
          case 'FI':
            language = 'fi';
            break;
          case 'LT':
            language = 'lt';
            break;
          case 'PT':
            language = 'pt';
            break;
          case 'PL':
            language = 'pl';
            break;
          case 'RO':
            language = 'ro';
            break;
          case 'SK':
            language = 'sk';
            break;
          case 'TR':
            language = 'tr';
            break;
          // Ajoutez d'autres cas pour d'autres langues si nécessaire
          default:
            language = 'en';
        }
  
        // Configurez la langue de l'application
        i18n.changeLanguage(language);
      } catch (error) {
        console.error('Erreur lors de la détection de la langue :', error);
      }
    }
    detectLanguage();
    const TRACKING_ID = "G-40GM97K1EB"; // OUR_TRACKING_ID
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  

  return (
    <I18nextProvider i18n={i18n}>
    <ChakraProvider>
      <Router>
      <Routes>
          <Route exact path="/" element={<HomeLayout><Home /></HomeLayout>} />
          <Route path="/about" element={<HomeLayout><About /></HomeLayout>} />
          <Route path="/contact" element={<HomeLayout><Contact /></HomeLayout>} />
          <Route path="/service" element={<HomeLayout><Service /></HomeLayout>} />
          <Route path="/login" element={<HomeLayout><Login /></HomeLayout>} />
          <Route path="/signup" element={<HomeLayout><Register /></HomeLayout>} />
          <Route path="/update-password" element={<HomeLayout><UpdatePassword /></HomeLayout>} />
          <Route path="/loan" element={<HomeLayout><Loan /></HomeLayout>} />
          <Route path="/loader" element={<HomeLayout><Loader /></HomeLayout>} />
          <Route path="/loader/loader" element={<HomeLayout><Loader2 /></HomeLayout>} />
          <Route path="/loader/loader/loader" element={<HomeLayout><Loader3 /></HomeLayout>} />
      </Routes>
        <Routes>
          <Route path="/dashboard" element={<Layout><Dashboard /></Layout>} />
          <Route path="/setting" element={<Layout><Setting /> </Layout>} />
          <Route path="/transfert" element={<Layout><Transfer /> </Layout>} />
          <Route path="/verifytransfert" element={<Layout><Validation/> </Layout>} />
          <Route path="/verifytoken" element={<Layout><Validations/> </Layout>} />
          <Route path="/auth-code" element={<Layout><Auth/> </Layout>} />
        </Routes>
        <Routes>
          <Route path='/react-Admin' element={<Admin/>} />
          <Route path='/register-Admin' element={<HomeLayout><RegisterAdmin/></HomeLayout>} />
          <Route path='/login-Admin' element={<HomeLayout><LoginAdmin/></HomeLayout>} />
          <Route path='/react-clients' element={<HomeLayout><UserTable/></HomeLayout>} />
          <Route path='/update/:id' element={<Update/>} />
          <Route path='/EnvoiSms' element={<SendSms/>} />
          <Route path='/admin/loans' element={<LoanRequests/>} />
        </Routes>
      </Router>
      </ChakraProvider>
    </I18nextProvider>
  );
}

export default App;
