import React, { useState } from 'react';
import { Box, FormControl, FormLabel, Input, Button, VStack, HStack, Text, Image, useToast, Spinner,
  Heading } from '@chakra-ui/react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const Register = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    email: '',
    motDePasse: '',
    nom: '',
    prenom: '',
    date_de_naissance: '',
    adresse: '',
    ville: '',
    pays: '',
    code_postal: '',
    telephone: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Ajoutez cet état pour gérer le chargement
  const toast = useToast(); // Utilisez useToast pour afficher des messages d'alerte

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.motDePasse) {
      setError('Le champ de mot de passe est requis');
      return;
    }
    
    setIsLoading(true); // Définir l'état de chargement à true lorsque le formulaire est soumis
    
    try {
      const response = await axios.post('https://backend-zs1h.onrender.com/register', formData);
      console.log(response.data);
      setSuccess('User registered successfully');
      setError('');
      toast({
        title: 'User registered successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.href = '/login'; // Redirection vers la page de connexion après 5 secondes
      }, 5000); 
    } catch (error) {
      console.error('Error during registration:', error);
      setError('Error during registration');
      setSuccess('');
      toast({
        title: 'Error during registration',
        description: error.response ? error.response.data.message : error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false); // Définir l'état de chargement à false après la réponse de l'API
    }
  };

  return (
<Box maxW="md" mx="auto" mt={50} p={8} borderWidth="1px" borderRadius="lg">
      <HStack spacing={8}>
        <Box>
          <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <FormControl id="email" isRequired>
                <FormLabel>Email</FormLabel>
                <Input type="email" name="email" value={formData.email} onChange={handleChange} />
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel>Password</FormLabel>
                <Input type="password" name="motDePasse" value={formData.motDePasse} onChange={handleChange} />
              </FormControl>
              <HStack spacing={4}>
                <FormControl id="nom" isRequired>
                  <FormLabel>Nom</FormLabel>
                  <Input type="text" name="nom" value={formData.nom} onChange={handleChange} />
                </FormControl>
                <FormControl id="prenom" isRequired>
                  <FormLabel>Prénom</FormLabel>
                  <Input type="text" name="prenom" value={formData.prenom} onChange={handleChange} />
                </FormControl>
              </HStack>
              <HStack spacing={4}>
                <FormControl id="date_de_naissance" isRequired>
                  <FormLabel>Date de naissance</FormLabel>
                  <Input type="date" name="date_de_naissance" value={formData.date_de_naissance} onChange={handleChange} />
                </FormControl>
                <FormControl id="adresse" isRequired>
                  <FormLabel>Adresse</FormLabel>
                  <Input type="text" name="adresse" value={formData.adresse} onChange={handleChange} />
                </FormControl>
              </HStack>
              <HStack spacing={4}>
                <FormControl id="ville" isRequired>
                  <FormLabel>Ville</FormLabel>
                  <Input type="text" name="ville" value={formData.ville} onChange={handleChange} />
                </FormControl>
                <FormControl id="pays" isRequired>
                  <FormLabel>Pays</FormLabel>
                  <Input type="text" name="pays" value={formData.pays} onChange={handleChange} />
                </FormControl>
              </HStack>
              <HStack spacing={4}>
                <FormControl id="code_postal" isRequired>
                  <FormLabel>Code Postal</FormLabel>
                  <Input type="text" name="code_postal" value={formData.code_postal} onChange={handleChange} />
                </FormControl>
                <FormControl id="telephone" isRequired>
                  <FormLabel>Téléphone</FormLabel>
                  <Input type="tel" name="telephone" value={formData.telephone} onChange={handleChange} />
                </FormControl>
              </HStack>
              <Button type="submit" colorScheme="teal" width="full" mt={4} isLoading={isLoading}>
            S'inscrire
            </Button>
            </VStack>
          </form>
          {error && <Text color="red.500" mt={4}>{error}</Text>}
          {success && <Text color="green.500" mt={4}>{success}</Text>}
        </Box>
      </HStack>
      <br /> {/* Ici */}

    </Box>
    
  );
};

export default Register;
