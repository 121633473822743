import React, { useState, useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Heading } from '@chakra-ui/react';
import axios from 'axios';

const UserTable = () => {
  const [utilisateurs, setUtilisateurs] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUtilisateurs = async () => {
      try {
        const response = await axios.get('https://backend-zs1h.onrender.com/admin/utilisateurs');
        setUtilisateurs(response.data.utilisateurs);
      } catch (error) {
        console.error("Erreur lors de la récupération des informations des utilisateurs:", error);
        setError("Erreur lors de la récupération des informations des utilisateurs.");
      }
    };

    fetchUtilisateurs();
  }, []);

  return (
    <div>
      <header>
        {/* Contenu de l'en-tête */}
        <h1>Mon Application</h1>
      </header>
      <nav>
        {/* Contenu de la barre de navigation */}
        <ul>
          <li>Accueil</li>
          <li>Profil</li>
          <li>Contact</li>
        </ul>
      </nav>
      <Heading mb="4">Les Informations des Clients</Heading>
      {error && <p>{error}</p>}
      <Table variant="striped" css={`
        width: 100%;
        border-collapse: collapse;
  
        th {
          background-color: #f1f1f1;
          font-weight: bold;
          text-align: left;
          padding: 8px;
        }
  
        td {
          padding: 8px;
          border-bottom: 1px solid #cccccc;
        }
  
        tr:last-child td {
          border-bottom: none;
        }
      `}>
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Nom</Th>
            <Th>Prénom</Th>
            <Th>Email</Th>
            <Th>Pays</Th>
            <Th>Téléphone</Th>
          </Tr>
        </Thead>
        <Tbody>
          {utilisateurs.map((utilisateur) => (
            <Tr key={utilisateur.id}>
              <Td>{utilisateur.id}</Td>
              <Td>{utilisateur.nom}</Td>
              <Td>{utilisateur.prenom}</Td>
              <Td>{utilisateur.email}</Td>
              <Td>{utilisateur.pays}</Td>
              <Td>{utilisateur.telephone}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  );
};

export default UserTable;