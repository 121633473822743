// Update.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Navbar, Nav, Row, Col, Card, NavDropdown, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faSms, faHome, faUser, faChartBar, faCreditCard, faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons';
import './Update.css'; // Importer le fichier CSS pour le style
import { useParams } from 'react-router-dom';

function Update() {
    const { id } = useParams();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [code1, setCode1] = useState('');
    const [code2, setCode2] = useState('');
    const [code3, setCode3] = useState('');
    const [code4, setCode4] = useState('');
    const [iban, setIban] = useState('');
    const [bic, setBic] = useState('');
    const [document, setDocument] = useState('');
  
    useEffect(() => {
      const fetchUser = async () => {
        try {
          const response = await axios.get(`https://backend-zs1h.onrender.com/admin/utilisateurs/${id}`);
          setUser(response.data.utilisateur);
          setLoading(false);
        } catch (error) {
          setError(error.response.data.message);
          setLoading(false);
        }
      };
  
      fetchUser();
    }, [id]);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      try {
        await axios.put(`https://backend-zs1h.onrender.com/admin/utilisateurs/${id}`, {
          iban,
          code1, // Utiliser la nouvelle valeur du code1
          code2, // Utiliser la nouvelle valeur du code2
          code3, // Utiliser la nouvelle valeur du code3
          code4, // Utiliser la nouvelle valeur du code4
          document,
          bic // Utiliser la nouvelle valeur du document
        });
        alert('Informations de l\'utilisateur mises à jour avec succès.');
      } catch (error) {
        console.error('Erreur lors de la mise à jour des informations de l\'utilisateur:', error);
        alert('Erreur lors de la mise à jour des informations de l\'utilisateur.');
      }
    };
  
    if (loading) return <p>Chargement...</p>;
    if (error) return <p>Erreur: {error}</p>;
  
    return (
        <>
            <Navbar bg="primary" variant="dark" expand="lg">
                <Container>
                    <Navbar.Brand href="#home">Finatex Admin</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                </Container>
            </Navbar>

            <div className="sidebar">
                <Nav className="flex-column">
                    <Nav.Link href="#home" className="sidebar-link"><FontAwesomeIcon icon={faHome} /> Accueil</Nav.Link>
                    <Nav.Link href="#profile" className="sidebar-link"><FontAwesomeIcon icon={faUser} /> Profil</Nav.Link>
                    <NavDropdown title={<span><FontAwesomeIcon icon={faChartBar} /> Statistiques</span>} id="basic-nav-dropdown" className="sidebar-link">
                        <NavDropdown.Item href="#transactions">Transactions</NavDropdown.Item>
                        <NavDropdown.Item href="#customers">Clients</NavDropdown.Item>
                        <NavDropdown.Item href="#loans">Prêts</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="#cards" className="sidebar-link"><FontAwesomeIcon icon={faCreditCard} /> Cartes</Nav.Link>
                    <Nav.Link href="#loans" className="sidebar-link"><FontAwesomeIcon icon={faMoneyCheckAlt} /> Prêts</Nav.Link>
                </Nav>
            </div><br/>
            <Container>
      <Row>
        <Col>
          <h2>Mise à jour de l'utilisateur</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Nom et Prénom</Form.Label>
              <Form.Control type="text" value={user.nom + ' ' + user.prenom} readOnly />
            </Form.Group>
            <Form.Group controlId="formIban">
              <Form.Label>Code validation 1</Form.Label>
              <Form.Control type="text" value={code1} onChange={(e) => setCode1(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formIban">
              <Form.Label>Code validation 2</Form.Label>
              <Form.Control type="text" value={code2} onChange={(e) => setCode2(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formIban">
              <Form.Label>Code validation 3</Form.Label>
              <Form.Control type="text" value={code3} onChange={(e) => setCode3(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formIban">
              <Form.Label>Code validation 4</Form.Label>
              <Form.Control type="text" value={code4} onChange={(e) => setCode4(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formIban">
              <Form.Label>IBAN</Form.Label>
              <Form.Control type="text" value={iban} onChange={(e) => setIban(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formIban">
              <Form.Label>BIC</Form.Label>
              <Form.Control type="text" value={bic} onChange={(e) => setBic(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formAccountNumber">
              <Form.Label>Nom du document</Form.Label>
              <Form.Control type="text" value={document} onChange={(e) => setDocument(e.target.value)} />
            </Form.Group>
            <Button variant="primary" type="submit">
              Mettre à jour
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
        </>
    );
}

export default Update;
