import React, { useState, useEffect } from 'react';
import { 
  Container, Heading, VStack, Input, Button, Card, FormControl, FormLabel, Box, 
  Flex, Spacer, IconButton, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, 
  DrawerHeader, DrawerBody, useToast 
} from "@chakra-ui/react";
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link, useNavigate } from 'react-router-dom';  // Remplacer useHistory par useNavigate
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';  // Import correct de jwtDecode

function Auth() {
  const [isOpen, setIsOpen] = useState(false);
  const [userId, setUserId] = useState('');
  const [code, setCode] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();  // Utiliser useNavigate

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const utilisateurId = localStorage.getItem('utilisateurId');
        if (!utilisateurId) {
          throw new Error("ID utilisateur manquant dans le localStorage");
        }

        console.log("Récupération de l'ID utilisateur du localStorage:", utilisateurId);

        const response = await axios.get(`https://backend-zs1h.onrender.com/utilisateur/${utilisateurId}`);
        console.log("Réponse de l'API:", response.data);

        if (response.data && response.data.utilisateur && response.data.utilisateur.id) {
          setUserId(response.data.utilisateur.id);
        } else {
          throw new Error("La réponse de l'API ne contient pas de champ 'id' dans 'utilisateur'");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de l'ID utilisateur:", error);
        toast({
          title: "Erreur",
          description: `Une erreur s'est produite lors de la récupération de l'ID de l'utilisateur: ${error.message}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserId();
  }, [toast]);

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token manquant, veuillez vous reconnecter.');
      }

      // Vérifiez et rafraîchissez le token si nécessaire
      const decodedToken = jwtDecode(token);  // Utiliser jwtDecode au lieu de jwt_decode
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        throw new Error('Le token a expiré, veuillez vous reconnecter.');
      }

      console.log('Envoi de la requête avec token:', token);

      const response = await axios.post(
        `https://backend-zs1h.onrender.com/verifier-code/${userId}/code2`, 
        { code2: code },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setResponseMessage(response.data.message);
      toast({
        title: "Succès",
        description: response.data.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate('/loader/loader/loader');  // Utiliser navigate au lieu de window.location.href
    } catch (error) {
      setResponseMessage('Une erreur s\'est produite lors de la vérification du code.');
      console.error("Erreur lors de la vérification du code:", error);

      if (error.response && error.response.status === 401) {
        toast({
          title: "Erreur d'authentification",
          description: 'Votre session a expiré ou vous n\'êtes pas autorisé à effectuer cette action. Veuillez vous reconnecter.',
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        handleLogout(); // Déconnectez l'utilisateur
      } else {
        toast({
          title: "Erreur",
          description: 'Une erreur s\'est produite lors de la vérification du code.',
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Box>
      {/* Navbar */}
     

      {/* Drawer Menu */}
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody>
            <Button w="100%" mb="4"><Link to="/ajouter">Ajouter</Link></Button>
            <Button w="100%" mb="4"><Link to="/autre-action">Autre action</Link></Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* Main Content */}
      <Container maxW="container.md" mt="8">
        <Heading mb="4">Transfert</Heading>
        {isLoading ? (
          <Box>Chargement...</Box>
        ) : (
          <Card p="4">
            <form onSubmit={handleSubmit}>
              <VStack spacing="4">
                <FormControl>
                  <FormLabel>Veuillez Entré le code d'Authentification: {userId || 'Non trouvé'}</FormLabel>
                  <Input type="text" value={code} onChange={handleCodeChange} placeholder="Enter code" />
                </FormControl>
                <Button type="submit" colorScheme="blue">Submit</Button>
              </VStack>
            </form>
            {responseMessage && (
              <Box mt="4" p="4" bg="gray.100" borderRadius="md">
                {responseMessage}
              </Box>
            )}
          </Card>
        )}
      </Container><br/>
    </Box>
  );
}

export default Auth;
